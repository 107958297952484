import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router, ActivatedRoute, Params, ParamMap } from "@angular/router";
import { JobService } from '../../job.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { switchMap } from 'rxjs/operators';


import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Trade } from 'src/app/models/trade';

@Component({
  selector: 'app-trade-details',
  templateUrl: './trade-details.component.html',
  styleUrls: ['./trade-details.component.scss']
})
export class TradeDetailsComponent {

  id: any;
  trade: Trade;
  ;
  display: boolean = false;

  orderForm: FormGroup;
  constructor(private formBuilder: FormBuilder,
    private router: Router, private _snackBar: MatSnackBar,
    private activatedRoute: ActivatedRoute,
    private jobService: JobService) { }




  ngOnInit() {
    this.id = this.activatedRoute.snapshot.params.id;


    this.orderForm = this.formBuilder.group({

      c1_value: ['', Validators.required],
      c2_value: ['', Validators.required],
      c1: ['', Validators.required],
      c2: ['', Validators.required],

      type: ['', Validators.required],
      trade_id: [1, Validators.required],


    });



    this.jobService.trade_details(this.id)
      .subscribe(res => {

        this.display = true;
        this.trade = res.data;

        this.trade.c1 = res.data.c1;
        this.trade.c2 =res.data.c2;

       // this.trade.c2_value = "1";
        this.trade.rate = res.data.rate;

       // this.trade.c1_value = "1";



      });

  }





  setforms() {


    if (this.trade == undefined)
      return "";

    console.log("setformssetformssetformssetforms");
    console.log(this.trade);






    this.orderForm.controls['trade_id'].setValue(this.trade.id);

    this.orderForm.controls['c1'].setValue(this.trade.c1);

    this.orderForm.controls['c2'].setValue(this.trade.c2);

  }
  start_p2p_trade() {

    const type = "buy";
    this.orderForm.controls['type'].setValue(type);


    this.jobService.start_p2p_trade(this.orderForm.value)
      .subscribe(res => {



        this._snackBar.open(res.message, "Close", {
          duration: 2000,
          verticalPosition: 'top',
          horizontalPosition: 'center'
        });
      

        if (res.success)
          this.router.navigate(['p2p-trade-processing', res.id]);


      });
  }

  onKey(event: any) { // without type info

    //alert(this.rate);

    let c2_value = this.trade.rate * event.target.value;
 
    //this.orderForm.controls['c2_value'].setValue(this.trade.c2);
 
    this.orderForm.controls['c2_value'].setValue(c2_value);

  }



  onKey1(event: any) { // without type info

    let c1_value = this.trade.rate * event.target.value;

  
    this.orderForm.controls['c1_value'].setValue(c1_value);

 
  }


}

