<h1>All Deposit Address</h1>
<mat-accordion>

  <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false" *ngFor="let t of currencydata"
    (click)="deposit(t.symbol)">
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{t.currency}}
      </mat-panel-title>
      <mat-panel-description>

      </mat-panel-description>
    </mat-expansion-panel-header>

    <table class="table table-striped">
      <thead>
        <tr>


          <th>ID</th>

          <th>User Id and Address</th>




          <th>Coin</th>

          <th>Date</th>

        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let t of address index as i;">

          <td>{{i+1}}</td>

          <td>{{t.user_id}}<br>{{t.Address}}</td>


          <td>{{t.coin}}</td>

          <td>{{t.cttimestamp}}</td>




        </tr>



      </tbody>
    </table>






  </mat-expansion-panel>
</mat-accordion>