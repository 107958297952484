import { AfterViewInit, Component, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { JobService } from '../../job.service';
declare const TradingView: any;


@Component({
  selector: 'app-system',
  templateUrl: './system.component.html',
  styleUrls: ['./system.component.css']
})
export class SystemComponent implements OnInit, AfterViewInit {
  pair: any;

  c1: any;
  c2: any;


  ticker:any;
   

  constructor(
    private jobService: JobService,
    private router: Router,
    private activatedRoute: ActivatedRoute,

  ) {

  }


  ngOnInit() {

    this.c1 = this.activatedRoute.snapshot.params.c1;
    this.c2 = this.activatedRoute.snapshot.params.c2;

    this.pair = { c1: this.c1, c2: this.c2 };

    console.log(this.pair);


    
    this.jobService.ticker()
    .subscribe(data => {
          this.ticker = data;

    });


  }


  sendto(c1:any,c2:any)
{

  this.pair = { c1   ,    c2 };

    this.router.navigate(['/system', c1, c2]);

}


  ngOnChanges(changes: SimpleChanges) {
    // changes.prop contains the old and the new value...

    this.c1 = this.activatedRoute.snapshot.params.c1;
    this.c2 = this.activatedRoute.snapshot.params.c2;

    this.pair = { c1: this.c1, c2: this.c2 };

    console.log(this.pair);

  }


  ngAfterViewInit() {
 
  }
}



