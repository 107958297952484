<mat-card>
    <mat-card-content>

      
       <table class="table    ">
          <thead>
             <tr class="tr">
                <td>Seller</td>
                <td>Title</td>
                <td>Rate</td>
                <td>Limit</td>
 
                <td>Action</td>
             </tr>
          </thead>
          <tbody>
             <tr class="tr" *ngFor="let q of trade_list_data">
                <td  (click)="view_trader_profile(q.user_id)" >{{q.name}}</td>
                <td>{{q.title}}</td>
 
 
                <td>{{q.margin}}</td>
                <td>{{q.minimum}}-{{q.maximum}} {{q.c2}}</td>
                <td (click)="start_trade(q.id)"><u>Start Trade</u></td>
             </tr>
          </tbody>
       </table>
 
 
    </mat-card-content>
 </mat-card>