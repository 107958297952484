import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './authentication/login/login.component';
//import { RegisterComponent } from './authentication/register/register.component';


import { AuthGuard } from './authentication/guards/auth.guard';
import { SystemComponent } from './component/exchange/system/system.component';
 import { ChangePasswordComponent } from './component/wallet/change-password/change-password.component';

//import { ForgetComponent } from './authentication/forget/forget.component';




// import { ResetpasswordComponent } from './authentication/resetpassword/resetpassword.component';


import { ListUserComponent } from "./component/wallet/user/list-user/list-user.component";
import { TransactionComponent } from './component/wallet/transaction/transaction.component';
import { DepositComponent } from './component/wallet/deposit/deposit.component';
import { LoginHistoryComponent } from './component/wallet/login-history/login-history.component';
// import { WithdrawAddressComponent } from './component/wallet/withdraw-address/withdraw-address.component';
import { UserProfileComponent } from './component/wallet/user-profile/user-profile.component';
// import { WithdrawComponent } from './component/wallet/withdraw/withdraw.component';
// import { DepositComponent } from './component/wallet/deposit/deposit.component';
// import { UserProfileComponent } from './component/wallet/user-profile/user-profile.component';
// import { ChangePasswordComponent } from './component/wallet/change-password/change-password.component';
// import { GeneralSettingComponent } from './component/wallet/general-setting/general-setting.component';
// import { SubscriptionComponent } from './component/wallet/subscription/subscription.component';

// import { CronComponent } from './component/wallet/cron/cron.component';
// import { ConfigureEmailComponent } from './component/wallet/configure-email/configure-email.component';
import { BankAccountComponent } from './component/wallet/bank-account/bank-account.component';
// import { PaymentFormComponent } from './component/wallet/payment-form/payment-form.component';
// import { ProfileEnableComponent } from './component/wallet/profile-enable/profile-enable.component';
// import { MembershipComponent } from './component/wallet/membership/membership.component';
// import { AddPlanComponent } from './component/wallet/add-plan/add-plan.component';
// import { DashboardComponent } from './component/wallet/dashboard/dashboard.component';
// import { AdminProfileComponent } from './component/wallet/admin-profile/admin-profile.component';
// import { IncomingNotificationComponent } from './component/wallet/incoming-notification/incoming-notification.component';
// import { DeleteUserAccountRequestComponent } from './component/wallet/user/delete-user-account-request/delete-user-account-request.component';

// import { UserDashboardComponent } from './component/wallet/user-dashboard/user-dashboard.component';

// import { TradingComponent } from './component/wallet/trading/trading.component';
// import { PairchangeComponent } from './component/wallet/pairchange/pairchange.component';
// import { CurrencyComponent } from './component/wallet/currency/currency.component';
// import { PairManagmentComponent } from './component/wallet/pair-managment/pair-managment.component';
// import { KycDocumentComponent } from './component/wallet/kyc-document/kyc-document.component'
// import { NonKycUsersComponent } from './component/wallet/user/non-kyc-users/non-kyc-users.component';
// import { ConfirmPaymentComponent } from './component/wallet/confirm-payment/confirm-payment.component';
// import { UserDepositAddressComponent } from './component/wallet/user-deposit-address/user-deposit-address.component';
// import { UserDepositComponent } from './component/wallet/user-deposit/user-deposit.component';
// import { SendfundComponent } from './component/wallet/sendfund/sendfund.component';
// import { KycUsersComponent } from './component/wallet/user/kyc-users/kyc-users.component';
// import { UserWithdrawsComponent } from './component/wallet/user-withdraws/user-withdraws.component';
// import { LoginHistoryComponent } from './component/wallet/login-history/login-history.component';
// import { NotificationComponent } from './component/wallet/notification/notification.component';
// import { EditPairComponent } from './component/wallet/edit-pair/edit-pair.component';
// import { AllDepositAddressComponent } from './component/wallet/all-deposit-address/all-deposit-address.component';

// import { ForgetgoogleauthComponent } from './authentication/forgetgoogleauth/forgetgoogleauth.component';
// import { ResetgoogleauthComponent } from './authentication/resetgoogleauth/resetgoogleauth.component';
// import { EthAddressListComponent } from './component/wallet/eth-address-list/eth-address-list.component';
import { AddressformComponent } from './component/wallet/addressform/addressform.component';
import { UserDepositAddressComponent } from './component/wallet/user-deposit-address/user-deposit-address.component';
import { UserDepositComponent } from './component/wallet/user-deposit/user-deposit.component';
import { UserDashboardComponent } from './component/wallet/user-dashboard/user-dashboard.component';
import { RegisterComponent } from './authentication/register/register.component';
import { AllDepositAddressComponent } from './component/wallet/all-deposit-address/all-deposit-address.component';
import { DashboardComponent } from './component/wallet/dashboard/dashboard.component';
import { BuyTradeComponent } from './component/trade/buy-trade/buy-trade.component';
import { TradeDetailsComponent } from './component/trade/trade-details/trade-details.component';
import { P2pTradeProcessingComponent } from './component/trade/p2p-trade-processing/p2p-trade-processing.component';
import { CreateTradesComponent } from './component/trade/manage/create-trades/create-trades.component';
import { TradesComponent } from './component/trade/manage/trades/trades.component';
import { SellTradeComponent } from './component/trade/sell-trade/sell-trade.component';
import { P2pTradeOrdersComponent } from './component/trade/p2p-trade-orders/p2p-trade-orders.component';
import { NotificationsComponent } from './component/notifications/notifications.component';
import { TraderProfileComponent } from './component/trade/trader-profile/trader-profile.component';
import { CurrencyComponent } from './component/wallet/currency/currency.component';


// import { WithdrawAddressComponent } from './component/wallet/withdraw-address/withdraw-address.component';
// import { EditCurrencyComponent } from './component/wallet/edit-currency/edit-currency.component';
  import { WithdrawlistComponent } from './component/wallet/withdrawlist/withdrawlist.component';
// import { AdminWalletComponent } from './component/wallet/admin-wallet/admin-wallet.component';
// import { UserDashboardListComponent } from './component/wallet/user-dashboard-list/user-dashboard-list.component';
// import { BxcoinComponent } from './component/wallet/bxcoin/bxcoin.component';
// import { AddUsersComponent } from './component/wallet/add-users/add-users.component'


// import { SystemComponent } from './component/exchange/system/system.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'login' },

  { path: 'login', component: LoginComponent },
  { path: 'register', component: RegisterComponent },

  { path: 'system/:c1/:c2', component: SystemComponent, canActivate: [AuthGuard] },



  //{ path: 'forget', component: ForgetComponent },
  //   { path: 'resetpassword', component: ResetpasswordComponent },
  { path: 'user/list-user', component: ListUserComponent, canActivate: [AuthGuard] },
  { path: 'transaction', component: TransactionComponent, canActivate: [AuthGuard] },
  { path: 'deposit', component: DepositComponent, canActivate: [AuthGuard] },
  //   { path: 'withdraw', component: WithdrawComponent, canActivate: [AuthGuard] },
  { path: 'user-profile', component: UserProfileComponent, canActivate: [AuthGuard] },
 { path: 'change-password', component: ChangePasswordComponent, canActivate: [AuthGuard] },
  //   { path: 'general-setting', component: GeneralSettingComponent, canActivate: [AuthGuard] },
  //   { path: 'subscription', component: SubscriptionComponent, canActivate: [AuthGuard] },
  //   { path: 'cron', component: CronComponent, canActivate: [AuthGuard] },
  //   { path: 'configure-email', component: ConfigureEmailComponent, canActivate: [AuthGuard] },
  { path: 'bank-account', component: BankAccountComponent, canActivate: [AuthGuard] },
  //   { path: 'payment-form', component: PaymentFormComponent, canActivate: [AuthGuard] },
  //   { path: 'profile-enable', component: ProfileEnableComponent, canActivate: [AuthGuard] },
  //   { path: 'membership', component: MembershipComponent, canActivate: [AuthGuard] },
  //   { path: 'add-plan', component: AddPlanComponent, canActivate: [AuthGuard] },
  { path: 'dashboard', component: UserDepositComponent, canActivate: [AuthGuard] },
  //   { path: 'admin-profile', component: AdminProfileComponent, canActivate: [AuthGuard] },
  //   { path: 'incoming-notification', component: IncomingNotificationComponent, canActivate: [AuthGuard] },
  //   { path: 'user/delete-user-account-request', component: DeleteUserAccountRequestComponent, canActivate: [AuthGuard] },

  //   { path: 'user-dashboard', component: UserDashboardComponent, canActivate: [AuthGuard] },

  { path: 'home', component: UserDepositComponent, canActivate: [AuthGuard] },
  //   { path: 'trading/:c1/:c2', component: SystemComponent, canActivate: [AuthGuard] },




  //   { path: 'pairchange/:c1/:c2', component: PairchangeComponent, canActivate: [AuthGuard] },

  { path: 'currency', component: CurrencyComponent, canActivate: [AuthGuard] },
  //   { path: 'pair-managment', component: PairManagmentComponent, canActivate: [AuthGuard] },
  //   { path: 'user/non-kyc-users', component: NonKycUsersComponent, canActivate: [AuthGuard] },
  //   { path: 'user/kyc-users', component: KycUsersComponent, canActivate: [AuthGuard] },
  //   { path: 'kyc-document', component: KycDocumentComponent, canActivate: [AuthGuard] },
  //   { path: 'confirm-payment', component: ConfirmPaymentComponent, canActivate: [AuthGuard] },
  { path: 'user-deposit', component: UserDepositComponent, canActivate: [AuthGuard] },
  { path: 'user-deposit-address', component: UserDepositAddressComponent, canActivate: [AuthGuard] },

  //   { path: 'sendfund', component: SendfundComponent, canActivate: [AuthGuard] },
  //   { path: 'user-withdraws', component: UserWithdrawsComponent, canActivate: [AuthGuard] },

  { path: 'login-history', component: LoginHistoryComponent, canActivate: [AuthGuard] },

  //   { path: 'notification', component: NotificationComponent, canActivate: [AuthGuard] },

  //    { path: 'edit-pair/:pair_id', component: EditPairComponent, canActivate: [AuthGuard] },
  { path: 'all-deposit-address', component: AllDepositAddressComponent, canActivate: [AuthGuard] },

  //   { path: 'forgetgoogleauth', component: ForgetgoogleauthComponent },
  //     { path: 'resetgoogleauth', component: ResetgoogleauthComponent },
  //    { path: 'eth-address-list', component: EthAddressListComponent, canActivate: [AuthGuard] },

  { path: 'addressform', component: AddressformComponent, canActivate: [AuthGuard] },
  // { path: 'withdraw-address', component: WithdrawAddressComponent, canActivate: [AuthGuard] },


  //   { path: 'edit-currency/:currency_id', component: EditCurrencyComponent, canActivate: [AuthGuard] },

   { path: 'withdrawlist', component: WithdrawlistComponent, canActivate: [AuthGuard] },
  //   { path: 'admin-wallet', component: AdminWalletComponent, canActivate: [AuthGuard] },

  //  { path: 'user-dashboard-list', component: UserDashboardListComponent, canActivate: [AuthGuard] },
  //  { path: 'bxcoin', component: BxcoinComponent, canActivate: [AuthGuard] },
  //  { path: 'add-users', component: AddUsersComponent, canActivate: [AuthGuard] },
  { path: 'trades/buy', component: BuyTradeComponent, canActivate: [AuthGuard] },
  { path: 'trades/sell', component: SellTradeComponent, canActivate: [AuthGuard] },

  { path: 'trade_detail/:id', component: TradeDetailsComponent, canActivate: [AuthGuard] },


  { path: 'manage_trade_orders', component: P2pTradeOrdersComponent, canActivate: [AuthGuard] },

  { path: 'p2p-trade-processing/:id', component: P2pTradeProcessingComponent, canActivate: [AuthGuard] },

  { path: 'manage/trades', component: TradesComponent, canActivate: [AuthGuard] },
  { path: 'manage/create_trade', component: CreateTradesComponent, canActivate: [AuthGuard] },

  { path: 'manage/trade/:id', component: CreateTradesComponent, canActivate: [AuthGuard] },


  { path: 'trader_profile/:user_id', component: TraderProfileComponent, canActivate: [AuthGuard] },




  { path: 'notifications', component: NotificationsComponent, canActivate: [AuthGuard] },

];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
