import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import { JobService } from '../../job.service';

import {MatSnackBar} from '@angular/material/snack-bar';
@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {

  constructor(private formBuilder: FormBuilder,private router: Router, private jobService: JobService,private _snackBar: MatSnackBar) { }

  addForm1: FormGroup;
  addForm: FormGroup;
  show:boolean=false;
ngOnInit() {
  this.addForm = this.formBuilder.group({
      id: [],
      current_password: ['', Validators.required], 
       new_password: ['', Validators.required],
        confirm_password: ['', Validators.required]
        
     
     
    });

     this.addForm1 = this.formBuilder.group({
      id: [],
      master_otp: ['', Validators.required], 
       email_otp: ['', Validators.required],
       user_email_otp:['']
        
     
     
    });

  }

   onSubmit2() {


 this.jobService.masterotp(this.addForm1.value)
      .subscribe( data => {
      console.log(data);
       this._snackBar.open(data.Message, "Close", {
      duration: 2000,

      verticalPosition: 'top',
      horizontalPosition: 'center'
    });

  this.show=data['otp'];
      });




   
  }


  onSubmit1(){

    this.jobService.setotp(this.addForm1.value)
      .subscribe( data => {
      console.log(data);
       this._snackBar.open(data.Message, "Close", {
      duration: 2000,

      verticalPosition: 'top',
      horizontalPosition: 'center'
    });
      });

  }

  onSubmit() {
    this.jobService.changepassword(this.addForm.value)
      .subscribe( data => {
      console.log(data);
  this._snackBar.open(data.message, "Close", {
      duration: 2000,

      verticalPosition: 'top',
      horizontalPosition: 'center'
    });
      });
  }
}
