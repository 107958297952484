<p>Widthdraw requests </p>



<mat-card>
  <mat-card-content>

    <form [formGroup]="addForm" (ngSubmit)="submit_withdraw()">

      <div class="form-group">
        <label for="currency">Currency </label>
 


        <select  class="form-control" formControlName="currency">
          <option *ngFor="let c  of currency"  >
            {{ c.currency}}
          </option>
        </select>


      </div>


      <div class="form-group">
        <label for="amount">Amount </label>
        <input type="text" formControlName="amount" placeholder="amount" name="amount" class="form-control" id="amount">
      </div>

      <div class="form-group">
        <label for="widthdraw_address">Widthdraw Address</label>
        <input type="text" formControlName="widthdraw_address" placeholder="widthdraw_address" name="widthdraw_address"
          class="form-control" id="widthdraw_address">
      </div>


      <div class="form-group">
        <label for="otp">OTP</label> 
        <input type="text" formControlName="otp" placeholder="otp" name="otp"
          class="form-control" id="otp"> <div (click)="getOtp()"  class="btn btn-primary btn-sm w-100 ">Get OTP</div>
      </div>

    


      <div class="form-group">

      <button class="btn btn-primary  w-100 mt-5">Submit Widthdraw</button>
    </div>

    </form>


  </mat-card-content>
</mat-card>






<mat-card>
  <mat-card-content>
    <button (click)="widthdraw_list('recent')" class="btn btn-primary btn-sm btn-block">Recent Record</button>

    <button (click)="widthdraw_list('all')" class="btn btn-primary btn-sm btn-block">All Record</button>
    
    
    <table class="table">

      <tr>

        <td> ID </td>



        <td>Amount</td>




        <td>Created at</td>
        <td>Status</td> 
      </tr>



      <tr *ngFor="let data of transactions " (click)="withdrawstatus(data.id,data.email,data.first_name)">





        <td>{{ data.id }}</td>




        <td>{{ data.amount | number }} {{ data.coin }}</td>



        <td>{{ data.created_at }}</td>
        <td>{{ data.status }}</td>
 




      </tr>



    </table>


  </mat-card-content>
</mat-card>