<div class="row">
  <div class="col " *ngFor="let t of currency ;">

    <mat-card (click)="generate_address(t.symbol)">






      <img mat-card-image class="img-thumbnail" src="{{t.icon}}" alt="{{t.symbol}}">
      <mat-card-content>






      </mat-card-content>

      <mat-card-actions>
        <button mat-button>{{t.currency}}</button>

      </mat-card-actions>
    </mat-card>
  </div>





</div>

<div class="row" *ngIf="dataSource_tbl">
  <div class="col-md-12"   *ngIf="dataSource_tbl.length > 0">
    <table mat-table [dataSource]="dataSource_tbl" class="mat-elevation-z8">

      <!--- Note that these columns can be defined in any order.
            The actual rendered columns are set as a property on the row definition" -->

      <!-- Position Column -->
      <ng-container matColumnDef="position">
        <th mat-header-cell *matHeaderCellDef> </th>
        <td mat-cell *matCellDef="let element">
          <img mat-card-image height="50px" weight="50px" src="{{element.icon}}" alt="{{element.symbol}}">
          {{element.symbol}}
        </td>
      </ng-container>




      <!-- Weight Column -->
      <ng-container matColumnDef="weight">
        <th mat-header-cell *matHeaderCellDef>Balance </th>
        <td mat-cell *matCellDef="let element"> {{element.balance}} {{element.symbol}} </td>
      </ng-container>



      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Balance in USD </th>
        <td mat-cell *matCellDef="let element"> {{element.balance_usd}} USD </td>
      </ng-container>




      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>


  </div>
</div>





<div class="row mt-3">
  <div class="col " *ngIf="transactions">


    <mat-card  *ngIf="transactions.length > 0">



      <mat-card-content>


        <table class="table table-striped" >
          <thead>
            <tr>


              <th class="hidden">ID</th>

              <!-- <th>Type</th> -->
              <th>Amount</th>
              <th>Balance</th>
              <th>Coin</th>

              <th>Reference</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let t of transactions index as i;">

              <td>{{t.id}}</td>
              <!--  <td  >{{t.type}}</td> -->
              <td>{{t.amount}}</td>
              <td>{{t.balance}}</td>
              <td>{{t.coin}}</td>
              <td>{{t.reference}}</td>
              <td>{{t.updated_at | date}}</td>




            </tr>
          </tbody>
        </table>




      </mat-card-content>


    </mat-card>



  </div>
</div>


