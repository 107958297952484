import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { JobService } from 'src/app/component/job.service';

@Component({
  selector: 'app-trades',
  templateUrl: './trades.component.html',
  styleUrls: ['./trades.component.scss']
})
export class TradesComponent {
 
  trade_list_data:any;


  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private _snackBar: MatSnackBar,
    private jobService: JobService) { }



  ngOnInit() {



    this.trade_list();




  }

  trade_list() {

    this.jobService.get_self()
      .subscribe(res => {

        this.trade_list_data = res.data;


      });
  }


  edit_trade(id: any) {



    this.router.navigate(['edit_trade', id]);

  }
  update_status(id: any, status: any) {

    this.jobService.update_status(id, status)
      .subscribe(res => {

        this._snackBar.open(res.message, "Close", {
          duration: 2000,

          verticalPosition: 'top',
          horizontalPosition: 'center'

        });

        this.trade_list();
      });
  }

  delete(id: any ) {

    this.jobService.delete_trade(id )
      .subscribe(res => {

        this._snackBar.open(res.message, "Close", {
          duration: 2000,

          verticalPosition: 'top',
          horizontalPosition: 'center'

        });

        
    this.trade_list();
      });
  }




  


}
