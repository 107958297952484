<mat-card>
  <mat-card-content>

    <h5 class="card-title">All User </h5>



    <table class="table  ">



      <tr *ngFor="let t of users ">
        <td>

          {{t.id}}
        </td>

        <td>

          {{t.email}}
        </td>

        <td>

          {{t.created_at}}
        </td>

        <td>
          <button mat-button [matMenuTriggerFor]="menu">Menu</button>
          <mat-menu #menu="matMenu">


            <button *ngIf="authService.isSuperAdmin()" mat-menu-item (click)="deleteg2f(t.email)">Delete Google
              Auth</button>


            <button *ngIf="authService.isSuperAdmin()" mat-menu-item (click)="deleteUser(t.id)">Delete this user
            </button>


            <button *ngIf="authService.isSuperAdmin()" mat-menu-item (click)="changelogin(t.id)">Login as this
              user</button>


            <button *ngIf="authService.isSuperAdmin()" mat-menu-item
              (click)="sendto(t.id,t.email,t.first_name,t.balance)">Debit/Credit</button>


          </mat-menu>

        </td>
      </tr>
    </table>
  </mat-card-content>
</mat-card>