<mat-card>



   <mat-card-content>
      <a routerLink="/manage/create_trade"> Trade </a>







      <table class=" table bg-primary  ">
         <thead>
            <tr class="tr">

               <td>Title</td>
               <td>Rate</td>
               <td>Limit</td>

               <td>Action</td>
            </tr>
         </thead>
         <tbody>
            <tr class="tr" *ngFor="let q of trade_list_data">

               <td>{{q.title}}</td>


               <td>{{q.margin}}</td>
               <td>{{q.minimum}} {{q.maximum}}</td>

               <td>
                  <span (click)="edit_trade(q.id)"> Edit </span>

                  <span (click)="update_status(q.id,'publish')">
                     Publish </span>


                  <span (click)="update_status(q.id,'unpublish')"> Unpublish </span>


                  <span (click)="delete(q.id,'delete')"> Delete </span>


               </td>
            </tr>
         </tbody>
      </table>


   </mat-card-content>
</mat-card>