



<div class="saksh_chat_message mb-3" *ngFor="let message of chat_message">

    {{message.message }} <br /><br />
    {{message.username }}
  
    {{message.created_at }}

    <hr />
</div>



<form [formGroup]="chat_form">

    <label class="label">Send Message</label>




    <input type="text" class="form-control" id="message" formControlName="message" name="message">




    <button (click)="send_message( )" class="btn btn-success   btn-block  mt-2   w-100  ">Send Message</button>


</form>