<section class="vh-100">
  <div class="container-fluid">
    <div class="row">

      <div class="col-sm-4 px-0 d-none d-sm-block">
        <img src="https://auricoin.org/assets/images/dashboard/img_one.jpg" alt="Login image" class="w-100 vh-100"
          style="object-fit: cover; object-position: left;">
      </div>


      <div class="col-sm-8 text-black">










        <div class="  align-items-center h-custom-2 px-5 ms-xl-4 mt-5 pt-5 pt-xl-0 mt-xl-n5">

          <div class="form">
            <div class=" ">


              <div class="  content_welcome_row text-center">
                <img class="img-thumbnail h-45" style="height: 250px" src="/assets/logo_principal_.png" alt="#">



              </div>
            </div>



            <form (ngSubmit)="reister()" #registerForm="ngForm">



              <div class="form-group">
                <h2>Register </h2>
                <label for="name">Name</label>
                <input type="text" class="form-control" id="name" required ngModel #name="ngModel" name="name"
                  placeholder="Enter your registered Name ">
                <div *ngIf="name.invalid && name.touched" class="alert alert-danger">
                  Please provide your name!
                </div>
              </div>


              <div class="form-group">
                <label>Email Address<sup class="text-danger">*</sup></label>
                <input type="email" class="form-control" id="email" name="email" required email ngModel #email="ngModel"
                  placeholder="Enter your registered email address">
                <div *ngIf="email.invalid && email.touched" class="alert alert-danger">
                  Please provide a correct email address!
                </div>
              </div>
              <div class="form-group">
                <label>Password<sup class="text-danger">*</sup></label>
                <input type="password" class="form-control" id="password" name="password" required minlength="4" ngModel
                  #password="ngModel" placeholder="Enter your password">
                <div *ngIf="password.invalid && password.touched" class="alert alert-danger">
                  Password must have at least 4 characters
                </div>
              </div>
              <div class="form-group mb-2">




                <button type="submit" mat-raised-button color="primary"
                  [disabled]="registerForm.invalid">Register</button><br>
                <!--  <a routerLink="/login" style="float:right;">Login Here</a> -->

              </div>

              <a mat-raised-button color="primary" routerLink="/login">Login Here</a>
            </form>
          </div>

        </div>

      </div>

    </div>
  </div>
</section>