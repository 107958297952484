<div class="row fourwidget">


  <div class="col-md-3">




    <mat-card>
      <mat-card-content>



        <div class="card-title float-right"></div>
        <p class="card-text">Total {{total_users}} Users</p>

      </mat-card-content>
    </mat-card>

  </div>
  <div class="col-md-3">


    <mat-card>
      <mat-card-content>
        <div class="card-title float-right"></div>
        <p class="card-text">Total {{total_deposit}} payment Deposit </p>

      </mat-card-content>
    </mat-card>

  </div>
  <div class="col-md-3">

    <mat-card>
      <mat-card-content>
        <div class="card-title float-right"></div>
        <p class="card-text">Total {{total_transaction}} Transactions</p>

      </mat-card-content>
    </mat-card>

  </div>
  <div class="col-md-3">



    <mat-card>
      <mat-card-content>
        <div class="card-title float-right"></div>
        <p class="card-text"> Total {{total_membership}} Membership </p>

      </mat-card-content>
    </mat-card>

  </div>
</div>



<div class="row  mt-5">
  <div class="col-md-12">

    <mat-card>
      <mat-card-content>

        <h5 class="card-title">Transaction



        </h5>


        <div class="table-responsive">


          <table class="table table-striped">
            <thead>
              <tr>
                <th>ID</th>
                <th>User ID</th>
                <th>Time</th>
                <th>Description</th>
                <th>Type </th>
                <th>Amount</th>

                <th>Balance</th>

                <th>Status</th>

              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let q of transaction  | slice:0:10; ">
                <td>{{q.id}}</td>
                <td>{{q.user_id}}</td>
                <td>{{q.updated_at| date:"MM/dd/yy hh:mm:ss" }}</td>
                <td>{{q.description}}</td>
                <td>{{q.type}}</td>
                <td> {{q.amount}} {{q.coin}} </td>

                <td> {{q.balance}} {{q.coin}} </td>

                <td>{{q.status }}</td>





              </tr>
            </tbody>
          </table>
        </div>



      </mat-card-content>
    </mat-card>




  </div>
</div>