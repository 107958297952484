<mat-card>
  <mat-card-content>
    <h1>Login History</h1>


    <button (click)="login_history_list('recent')" class="btn btn-primary btn-sm btn-block">Recent Record</button>

    <button (click)="login_history_list('all')" class="btn btn-primary btn-sm btn-block">All Record</button>
    <br>
    <div class="table-responsive">


      <table>


        <tr *ngFor="let t of login_history index as i;">


          <td><br>{{t.created_at}}<br>{{t.ip}}<br>
            <hr>
          </td>




        </tr>

      </table>


    </div>

  </mat-card-content>
</mat-card>