<div class="row">




  <div class="col-md-6">
    <mat-card>
      <mat-card-content>
        <h1 class="card-title">Payment</h1>

        Available Wallet Balance &#8383; {{balance }}

        <br>
        <br>
        <form [formGroup]="addForm" (ngSubmit)="onSubmit()">

          <div class="form-group">
            <label for="amount">Amount</label>
            <input type="number" formControlName="amount" placeholder="Amount" name="amount" class="form-control"
              id="amount">
          </div>

          <div class="form-group">

            <label for="currency">Currency</label>

            <select name="coin" formControlName="coin" class="form-control" (change)="onChange($event.target.value)">

              <option *ngFor="let t of currency" value="{{t.symbol}}">{{t.currency}}</option>


            </select>
          </div>

          <div class="form-group">
            <label for="gender">Type</label>


            <select class="form-control" formControlName="type">





              <option value="debit_user">
                Debit
              </option>

              <option value="credit_user">
                Credit
              </option>


            </select>


          </div>



          <div class="form-group">
            <label for="description">Description</label>
            <textarea rows="4" cols="50" formControlName="description" placeholder="Description" name="description"
              class="form-control" id="description">

</textarea>




          </div>


          <button class="btn btn-sm  ">Submit</button>



        </form>
      </mat-card-content>
    </mat-card>
  </div>

  <div class="col-md-6">
    <mat-card>
      <mat-card-content>
        <h1 class="card-title">User Details</h1>


        <br>
        <table>
          <tr>
            <td>Id :</td>
            <td> {{id}}</td>
          </tr>

          <tr>
            <td>Username:</td>
            <td> {{name}} </td>
          </tr>
          <tr>
            <td>Email : </td>
            <td>{{email}} </td>
          </tr>
          <tr *ngFor="let t of currency1;">
            <td>Balance {{t.currency}}: </td>
            <td>{{t.balance}} {{t.currency}}
          </tr>

        </table>

        <br><br>
        <h1 class="card-title">Recent Transaction</h1>

        <table class="table table-striped">
          <thead>
            <tr>


              <th class="hidden">ID</th>

              <th>Type</th>
              <th>Amount</th>
              <th>Balance</th>
              <th>Coin</th>

              <th>Description</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let t of transactions index as i;">

              <td>{{t.id}}</td>
              <td>{{t.type}}</td>
              <td>{{t.amount}}</td>
              <td>{{t.balance}}</td>
              <td>{{t.coin}}</td>
              <td>{{t.description}}</td>
              <td>{{t.updated_at}}</td>




            </tr>
          </tbody>
        </table>

      </mat-card-content>
    </mat-card>
  </div>


</div>