import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router, ActivatedRoute, Params, ParamMap } from "@angular/router";
import { JobService } from '../../job.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { switchMap } from 'rxjs/operators';
import { User } from 'src/app/models/user.model';
//import { createChart } from 'lightweight-charts';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
//import { inherits } from 'util';

@Component({
  selector: 'app-tickers',
  templateUrl: './tickers.component.html',
  styleUrls: ['./tickers.component.css']
})
export class TickersComponent implements OnInit {

  ticker:any;
  constructor(private formBuilder: FormBuilder,
     private router: Router, 
     private jobService: JobService,
      private _snackBar: MatSnackBar, 
      private activatedRoute: ActivatedRoute ) { }

  ngOnInit() {

    
    this.jobService.ticker()
    .subscribe(data => {
          this.ticker = data;

    });



  }

}
