import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { JobService } from '../../job.service';
import { MatSnackBar } from '@angular/material/snack-bar';

import { Transaction } from 'src/app/models/transaction.model';

@Component({
  selector: 'app-login-history',
  templateUrl: './login-history.component.html',
  styleUrls: ['./login-history.component.css']
})
export class LoginHistoryComponent implements OnInit {

  addForm: FormGroup;
  login_history: Transaction[];
  msg: any;
  constructor(private formBuilder: FormBuilder, private router: Router, private jobService: JobService, private _snackBar: MatSnackBar) { }


  ngOnInit() {

  }

  login_history_list(type) {
    this.jobService.login_history(type)
      .subscribe(data => {

        this.login_history = data;
        console.log(data);

      });



  }

}
