 



<div class="row">
<div class="col-md-12">
  


<div class="card">


      <div class="card-body">
<h1>Search Address</h1>
 <form [formGroup]="addForm" (ngSubmit)="onSubmit()" > 
    
  <div class="form-group">
  
  <div class="text" >
    
      <input type="text" formControlName="address"  name="address" class="form-control" id="authcode">
   
              </div>
    </div>
  <button class="tn btn-success btn-sm">Submit</button>

</form>

</div></div>


<br><br>

<div class="card">


      <div class="card-body">
<h1>Wallet and User List</h1>

 <div  class="table-responsive">
    
  <table class="table table-striped">
    <thead>
    <tr>
      

      <th>ID</th>
     
<th>User Id</th>
<th>Email</th>
<th>Address</th>
<th>Coin</th>

<th>Date</th>
   
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let t of addresslist index as i;" >
  
<td >{{i+1}}</td>

  <td  >{{t.user_id}}</td>
   <td  >{{t.email}}</td>
      <td  >{{t.address}}</td>
  <td  >{{t.coin}}</td>
   
             <td  >{{t.cttimestamp}}</td>
                 
               


    </tr>
    </tbody>
  </table>
  



  

</div></div></div>





</div></div>