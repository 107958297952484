<section class="vh-100">
  <div class="container-fluid">
    <div class="row">

      <div class="col-sm-4 px-0 d-none d-sm-block">
        <img src="https://auricoin.org/assets/images/dashboard/img_one.jpg" alt="Login image" class="w-100 vh-100"
          style="object-fit: cover; object-position: left;">
      </div>


      <div class="col-sm-8 text-black">










        <div class="  align-items-center h-custom-2 px-5 ms-xl-4 mt-5 pt-5 pt-xl-0 mt-xl-n5">

          <div class="form">
            <div class=" ">


              <div class="  content_welcome_row text-center">
                <img class="img-thumbnail h-45" style="height: 250px" src="/assets/logo_principal_.png" alt="#">



                <h1><b>WELCOME</b></h1>
                <h3>Enter your information to log in with your account</h3>

              </div>
            </div>
            <form #loginForm="ngForm" (ngSubmit)="signIn()">






              <div class="form-group">

                <div class="input-group mb-4">


                  <input class="form-control input_border_right" id="email" required email ngModel #email="ngModel"
                    name="email" type="email" placeholder="Enter your email" autocomplete="off" required="required">
                  <div class="mt-2 text-center invalid-feedback" id="email_alert"></div>
                </div>

              </div>

              <span *ngIf="otp">
                <div class="form-group">




                  <div class="input-group mb-4">


                    <input type="password" class="form-control" id="password" name="password" required minlength="4"
                      ngModel #password="ngModel" placeholder="Enter your password">
                  </div>


                </div>







                <div class="form-group">

                  <button type="button" (click)="checkGAUTH()" class="btn btn-primary">Login</button>

                </div>

              </span>
              <div *ngIf="otp1">
                <div class="form-group">
                  <label>Auth Code<sup class="text-danger">*</sup></label>
                  <input type="number" class="form-control" id="otp" name="otp" required minlength="4" ngModel
                    #otp="ngModel" placeholder="Enter your Auth Code">


                </div>
                <button type="submit" class="btn btn-custom btn-sm" [disabled]="loginForm.invalid">Verify Code and
                  login</button>



              </div>





              <div *ngIf="gotp">

                <div class="form-group">
                  <label>Google Auth<sup class="text-danger">*</sup></label>
                  <input type="text" class="form-control" id="google_auth" name="google_auth" required ngModel
                    #password="ngModel" placeholder="Enter your google_auth">


                </div>



                <div class="form-group">

                  <button type="submit" class="btn btn-custom btn-sm" [disabled]="loginForm.invalid">Login</button>

                </div>

              </div>

              <a routerLink="/register">Register Here </a>



              <a routerLink="/forgetgoogleauth" style="float:right;"> Forget Google Auth</a><br>



              <a routerLink="/forget" style="float:right;"> Forget Password</a>


            </form>
          </div>

        </div>

      </div>

    </div>
  </div>
</section>