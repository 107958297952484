import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";

import { JobService } from '../../job.service';

import { Transaction } from 'src/app/models/transaction.model';

import { User } from 'src/app/models/user.model';


import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  transaction: Transaction[];
  panelOpenState = false;
  closeResult: string;
  editForm: FormGroup;
  addForm: FormGroup;
  title: string;
  receive_address: any;
  receive_coin: any;
  testCount: any;
  total_transaction: any;
  users: User[];
  testCount1: any;
  testCountuser: any;
  total_deposit: any;
  total_membership: any;
  total_users: any;
  testCountmembership: any;
  msg: any;



  constructor(private _snackBar: MatSnackBar,
    private formBuilder: FormBuilder, private router: Router,
    private jobService: JobService) { }

  ngOnInit() {

    this.testCount = 1;
    this.jobService.alltransaction('recent')
      .subscribe(data => {
        this.transaction = data;
        this.testCount = this.transaction.length;
        console.log(this.testCount);
        this.total_transaction = this.testCount;
        // alert(total_transaction);


      });

    this.jobService.getmembership()
      .subscribe(data => {

        this.users = data;
        console.log(this.users);
        this.testCountmembership = this.users.length;
        this.total_membership = this.testCountmembership;


      });

    this.jobService.getUsers()
      .subscribe(data => {

        this.users = data;
        console.log(this.users);
        this.testCountuser = this.users.length;
        this.total_users = this.testCountuser;

        this.jobService.transactions_deposits('recent')
          .subscribe(data => {

            // this.transaction = data ;
            this.testCount1 = this.transaction.length;
            console.log(this.testCount1);
            this.total_deposit = this.testCount1;
          });



      });

  }

}
