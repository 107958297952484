<div class="trade_order_info" *ngIf="trade_order_info">

    <div class="title">


        {{trade_order_info.details_page_title}}



    </div>


    <div class="sub_title">


        {{trade_order_info.details_page_sub_title}}
        <hr>
        status {{trade_order_info.status}} <br /> payment_status {{trade_order_info.payment_status}}

    </div>
    <hr />


    <div class="row">

        <div class="col-md-6">
            <app-discussion [id]="id"></app-discussion>
        </div>

        <div class="col-md-6">
            {{trade_order_info.terms_and_conditions}}


            <div class="trade_handle">


                <button *ngIf="trade_order_info.button.make_payment" (click)="make_payment_btn(trade_order_info.id)">
                    Mark as paid </button>



                <button *ngIf="trade_order_info.button.release_escrow" (click)="release_payment(trade_order_info.id)">
                    Release Payment</button>

                <button *ngIf="trade_order_info.button.dispute_escrow" (click)="dispute_payment(trade_order_info.id)">
                    Dispute Payment</button>


                <button *ngIf="trade_order_info.button.cancel_escrow" (click)="cancel_order(trade_order_info.id)">
                    Cancel Payment</button>

            </div>


        </div>


    </div>


</div>