<mat-card>



    <mat-card-content>


        <table class=" table   ">
            <thead>
                <tr class="tr">

                    <td>Trade Order ID</td>
                    <td>Trader email</td>
                    <td>Type</td>
                    <td>Rate</td>
                    <td>Trade Amount</td>

                    <td>Action</td>
                </tr>
            </thead>
            <tbody>
                <tr class="tr" *ngFor="let q of trade_order_list_data">



                    <td>{{q.id}}</td>

                    <td>{{q.merchant_email}}</td>
                    <td>{{q.trade_type}}</td>
                    <td>{{q.rate}}</td>
                    <td>{{q.c1_value}} {{q.c1}}</td>

                    <td>
                        <span (click)="sendto(q.id)"> View </span>


                    </td>
                </tr>
            </tbody>
        </table>


    </mat-card-content>
</mat-card>