 
<div class="profile" *ngIf="trader">
 
    <div class="header">
        Trades {{trader.email}}
    </div>
    <mat-card>
        <mat-card-content>
            <table class="table    ">
                <thead>
                    <tr class="tr">
                       <td>Id</td>
                        
                      
                        <td>Rate</td>
                        <td>Limit</td>

                        <td>Action</td>
                    </tr>
                </thead>
                <tbody>
                    <tr class="tr" *ngFor="let q of trader.trades">
                        <td (click)="view_trader_profile(q.user_id)">{{q.id}}</td>
                   


                        <td>{{q.margin}}</td>
                        <td>{{q.minimum}} {{q.maximum}}</td>
                        <td (click)="check_trade(q.id)"><u>View Trade</u></td>
                    </tr>
                </tbody>
            </table>


        </mat-card-content>
    </mat-card>



    <div class="header">Trade Orders</div>



    <mat-card>
        <mat-card-content>
            <table class="table    ">
                <thead>
                    <tr class="tr">
                        <td>ID</td>
                        <td>Pair</td>
                        <td>Amount</td>
                        <td>Status</td>


                        <td>Action</td>
                    </tr>
                </thead>
                <tbody>
                    <tr class="tr" *ngFor="let q of trader.trade_orders">
                        <td (click)="view_trader_profile(q.user_id)">{{q.id}}</td>
                        <td>{{q.c1}}/{{q.c2}}</td>


                        <td>{{q.c1_value}} {{q.c1}} </td>
                        <td>{{q.status}}</td>
                        <td (click)="check_trade_order(q.id)"><u>View</u></td>
                    </tr>
                </tbody>
            </table>


        </mat-card-content>
    </mat-card>

</div>