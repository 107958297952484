

import { AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from '@angular/router';
import { Trade } from 'src/app/models/trade';
import { JobService } from '../../job.service';






@Component({
  selector: 'app-sell-trade',
  templateUrl: './sell-trade.component.html',
  styleUrls: ['./sell-trade.component.scss']

})
export class SellTradeComponent implements OnInit {
  dataSource;

  columnsToDisplay = [];
  expandedElement: null;

  trade_list_data: any;


  loading: boolean = true;

  testCount: any;

  constructor(private formBuilder: FormBuilder, private router: Router, private jobService: JobService) { }

  ngOnInit() {


    // user will sell the bitcoin from the add where trader is buying  so buy type add are here

    this.trade_list("Buy");




  }

  trade_list(type) {

    this.jobService.get_trade_lists(type)
      .subscribe(res => {

        this.trade_list_data = res.data;

        this.loading = false;
      });
  }


  start_trade(id: any) {



    this.router.navigate(['trade_detail', id]);

  }

  view_trader_profile(user_id:any)
  {

    this.router.navigate(['trader_profile', user_id]);

  }
}
