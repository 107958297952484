import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ApiResponse } from "../models/api.response";
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

@Injectable()
export class AuthService {



  private readonly baseurl = 'https://appapiv9.pix.exchange/api/auth/';



  private readonly loginUrl1 = 'https://appapiv9.pix.exchange/api/auth/googleauthverify';


  private readonly registerUrl1 = 'https://appapiv9.pix.exchange/api/auth/register';


  private readonly loginUrl2 = 'https://appapiv9.pix.exchange/api/auth/login';

  baseUrl = 'https://appapiv9.pix.exchange/api/';



  baseUrlauth = 'https://appapiv9.pix.exchange/api/auth/';



  constructor(

    private router: Router,
    private http: HttpClient
  ) { }


  register(body) {
    return this.http.post(this.baseurl + "register", body);
  }

  login(body) {
    return this.http.post(this.baseurl + "login", body);
  }

  login1(body) {
    return this.http.post(this.baseurl + "login", body);
  }


  getlogo1() {
    return "http://auricoin.trade/assets/images/home/logo_principal.png";
  }


  getlogo(): Observable<Array<any>> {
    return this.http.get<Array<any>>(this.baseUrl + 'getlogo');
  }

  checkgoogleauth(body) {
    return this.http.post(this.baseurl + "googleauthtest", body);
  }

  sendmail(body) {
    return this.http.post(this.baseurl + "sendemail", body);

  }

  forgetpasswordemail(data: any) {
    return this.http.post<any>(this.baseurl + 'forgetpassword', data);
  }


  googleauthforgetpasswordemail(data: any) {
    return this.http.post<any>(this.baseurl + 'googleauthforgetpassword', data);
  }
  resetforgetpasswordemail(data: any): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.baseurl + 'resetforgetpassword', data);
  }

  googleauthresetforgetpasswordemail(data: any): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.baseurl + 'googleauthresetforgetpassword', data);
  }

  logout() {
    localStorage.clear();

    this.router.navigate(['/login']);
  }

  isAuthenticated() {
 


    return localStorage.getItem('token') !== null;

  }

  isAdmin() {
    let roll = window.localStorage.getItem("roll");

    return parseInt(roll) > 9;

  }
  isSuperAdmin() {
    let roll = window.localStorage.getItem("roll");

    return parseInt(roll) > 11;

  }

  showTrade() {
     
    return  true;

  }

  isUser() {
    let roll = window.localStorage.getItem("roll");

    return parseInt(roll) < 10;

  }

  getToken() {
    let token = localStorage.getItem('token');



    return token;
  }
}