import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { JobService } from '../../job.service';
import { MatSnackBar } from '@angular/material/snack-bar';

import { Transaction } from 'src/app/models/transaction.model';

@Component({
  selector: 'app-all-deposit-address',
  templateUrl: './all-deposit-address.component.html',
  styleUrls: ['./all-deposit-address.component.css']
})
export class AllDepositAddressComponent implements OnInit {
  panelOpenState = false;
  constructor(private formBuilder: FormBuilder, private router: Router, private jobService: JobService, private _snackBar: MatSnackBar) { }
  testCount;
  currencydata;
  loading;
  address: Transaction[];
  ngOnInit() {


    this.jobService.activecurrencylist()
      .subscribe(data => {

        this.currencydata = data;
        console.log(data);

      });


  }

  deposit(coin) {

    this.loading = true;

    this.jobService.alldepositaddress(coin)
      .subscribe(data => {

        this.address = data;
        this.testCount = data.length;

        this.loading = false;
      });

  }
}
