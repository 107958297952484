<mat-card>
  <mat-card-content>


    <h1>Deposit





      <button class="btn  float-right  btn-primary btn-sm islink" (click)="open2()">Get Deposit
        address</button>


    </h1>

    <button (click)="deposit_list('recent')" class="btn btn-primary btn-sm btn-block">Recent Record</button>

    <button (click)="deposit_list('all')" class="btn btn-primary btn-sm btn-block">All Record</button>
    <br>

    <div class="table-responsive">



      <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="mat-elevation-z8">
        <ng-container matColumnDef="{{column}}" *ngFor="let column of columnsToDisplay">
          <th mat-header-cell *matHeaderCellDef> {{column}} </th>
          <td mat-cell *matCellDef="let element"> {{element[column]}} </td>
        </ng-container>

        <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
        <ng-container matColumnDef="expandedDetail">
          <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
            <div class="example-element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
              <div class="example-element-diagram">
                <div class="example-element-position"> {{element.id}} </div>
                <div class="example-element-symbol"> {{element.coin}} </div>

              </div>
              <div class="example-element-description">

                Description: {{element.description}}<br>
                Transaction hash: {{element.transaction_hash}} Confirmation : {{element.confirmation}}<br>
                Invoice Id: {{element.invoice_id}}

                <span class="example-element-description-attribution"> </span>
              </div>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let element; columns: columnsToDisplay;" class="example-element-row"
          [class.example-expanded-row]="expandedElement === element"
          (click)="expandedElement = expandedElement === element ? null : element">
        </tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
      </table>






    </div>

    <div *ngIf="loading">
      <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>

      </div>
    </div>
    <div class="text-center" *ngIf="testCount==0">



      <p>No Test found</p>
    </div>



  </mat-card-content>
</mat-card>