<mat-card>
  <mat-card-content>

    <h1>Transaction

    </h1>

    <button (click)="transaction_list('recent')" class="btn btn-primary btn-sm btn-block">Recent Record</button>

    <button (click)="transaction_list('all')" class="btn btn-primary btn-sm btn-block">All Record</button>






    <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="mat-elevation-z8">
      <ng-container matColumnDef="{{column}}" *ngFor="let column of columnsToDisplay">


        <th mat-header-cell *matHeaderCellDef> {{column}}</th>


        <td mat-cell *matCellDef="let element">{{element[column]}} </td>

        <!-- <td mat-cell *matCellDef="let element"> To: {{element.status}} <br>From: {{element.status}}</td>-->
      </ng-container>

      <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
      <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
          <div class="example-element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
            <div class="example-element-diagram">
              <div class="example-element-position"> </div>
              <div class="example-element-symbol"> {{element.coin}} </div>

            </div>
            <div class="example-element-description">

              <table>

                <tr>
                  <th width="350">Description: </th>

                  <th>Status </th>
                </tr>


                <tr>
                  <td> {{element.description}} </td>
                  <td>{{element.status}}</td>
                </tr>






              </table>


              <span class="example-element-description-attribution"> </span>
            </div>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
      <tr mat-row *matRowDef="let element; columns: columnsToDisplay;" class="example-element-row"
        [class.example-expanded-row]="expandedElement === element"
        (click)="expandedElement = expandedElement === element ? null : element">
      </tr>
      <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
    </table>



  </mat-card-content>
</mat-card>