 


      <mat-card>
         <mat-card-content>


      <mat-tab-group mat-align-tabs="start">
         <mat-tab label="Sell Order" class="tabs     ">
            <table class="table table-hover table-borderless      table-sm">
               <thead>
                  <tr class="tr">
                     <td><small>PRICE</small></td>
                     <td><small>TOTAL</small></td>
                     <td><small>AMOUNT</small></td>
                     <td><small>COUNT</small></td>
                  </tr>
               </thead>
               <tbody>
                  <tr class="tr" *ngFor="let q of sellorder">
                     <td><small>{{q.price | number}}</small></td>
                     <td><small>{{q.total | number}}</small></td>
                     <td><small>{{q.amount| number}}</small></td>
                     <td><small>{{q.count}}</small></td>
                  </tr>
               </tbody>
            </table>
         </mat-tab>
         <mat-tab label="Buy Order" class="tabs">
            <table class="table table-hover table-borderless      table-sm">
               <thead>
                  <tr class="tr">
                     <td><small>COUNT</small></td>
                     <td><small>AMOUNT</small></td>
                     <td><small>TOTAL</small></td>
                     <td><small>PRICE</small></td>
                  </tr>
               </thead>
               <tbody>
                  <tr class="tr" *ngFor="let q of buyorder">
                     <td><small>{{q.count}}</small></td>
                     <td><small>{{q.amount | number}}</small></td>
                     <td><small>{{q.total | number }}</small></td>
                     <td><small>{{q.price | number}}</small></td>
                  </tr>
               </tbody>
            </table>
         </mat-tab>
         <mat-tab label="Recent Order" class="tabs     ">
            <table class="table table-borderless table-hover       table-sm">
               <thead>
                  <tr class="tr">
                     <td><small>TIME</small></td>
                     <td><small>PRICE</small></td>
                     <td><small>AMOUNT</small></td>
                  </tr>
               </thead>
               <tbody>
                  <tr class="tr" *ngFor="let q of tradehistory">
                     <td><small>{{q.created_at | date:'hh:mm:ss'}}</small></td>
                     <td><small>{{q.rate| number}}</small></td>
                     <td><small>{{q.quantity| number}}</small></td>
                  </tr>
               </tbody>
            </table>
         </mat-tab>
      </mat-tab-group>
      
   </mat-card-content>
</mat-card>