<div class="row  ">
    <div class="col-md-4">


        <mat-card>
            <mat-card-content>


                <table class="table table-hover table-borderless  table-sm">

                    <tbody>
                        <tr class="tr" *ngFor="let q of ticker">

                            <td routerLinkActive="router-link-active">
                                <a (click)="sendto(q.c1,q.c2)" class="ticket_text"> <small>{{q.c1 }}  </small>
                                </a>
                            </td>

                            <td class="ticket_text"><small>{{q.rate | number}}</small>
                        </tr>
                    </tbody>
                </table>


            </mat-card-content>
        </mat-card>

        <app-booking-form [pair]="pair"> </app-booking-form>
    </div>

    <div class="col-md-8">



        <mat-card>
            <mat-card-content>
            </mat-card-content>
        </mat-card>







        <app-order-history></app-order-history>
    </div>



</div>