import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Job } from '../models/job';

import { ApiResponse } from "../models/api.response";
import { Transaction } from "../models/transaction.model";
import { User } from '../models/user.model';
import { Observable } from 'rxjs';
import { Document } from "../models/document.model";


@Injectable({
  providedIn: 'root'
})
export class JobService {



 

  constructor(private http: HttpClient) { }


  baseUrl = 'https://appapiv9.pix.exchange/api/';

  baseUrlauth = 'https://appapiv9.pix.exchange/api/auth/';

  unread_notification_count() : Observable<any> {
    return this.http.get<any>(this.baseUrl + 'auth/p2ptrade/unread_notification_count');
  }

  get_self_notifications() : Observable<any> {
    return this.http.get<any>(this.baseUrl + 'auth/p2ptrade/notifications');
  }

  about_trader(user_id:any) : Observable<any> {
    return this.http.get<any>(this.baseUrl + 'auth/p2ptrade/trader/'+user_id);
  }


 
  get_self_trade_orders( ): Observable<any> {
    return this.http.get<any>(this.baseUrl + 'auth/p2ptrade/get_self_trade_orders');
  }
 
  get_trade_lists(type:any): Observable<any> {
    return this.http.get<any>(this.baseUrl + 'auth/p2ptrade/trades?type=' + type);
  }
  trade_details(id:any): Observable<any> {
    return this.http.get<any>(this.baseUrl + 'auth/p2ptrade/trades/' + id);
  }
  
  

  start_p2p_trade(data: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'auth/p2ptrade/start_p2p_trade', data);
  }


  trade_order_details(id:any): Observable<any> {
    return this.http.get<any>(this.baseUrl + 'auth/p2ptrade/trade_order_details/' + id);
  }
  
  get_messages(id:any): Observable<any> {
    return this.http.get<any>(this.baseUrl + 'auth/message/get_messages/' + id);
  }
   

  send_message(data: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'auth/message/send_message', data);
  }




  add_new_trade(data: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'auth/p2ptrade/manage/trade/create', data);
  }


  get_self( ): Observable<any> {
    return this.http.get<any>(this.baseUrl + 'auth/p2ptrade/manage/trade/get_self');
  }

  update_status(id:any,status:any): Observable<any> {
    return this.http.get<any>(this.baseUrl + 'auth/p2ptrade/manage/trade/update_status/' + id+'/'+status);
  }
  
  delete_trade(id:any ): Observable<any> {
    return this.http.get<any>(this.baseUrl + 'auth/p2ptrade/manage/trade/delete/' + id );
  }
  
  


  cancel_trade(id: any): Observable<any> {
    return this.http.get<any>(this.baseUrl + 'auth/p2ptrade/cancel_trade/' +id);
  } 


  make_payment(id: any): Observable<any> {
    return this.http.get<any>(this.baseUrl + 'auth/p2ptrade/make_payment/' +id);
  } 
  
  
  dispute_payment(id: any): Observable<any> {
    return this.http.get<any>(this.baseUrl + 'auth/p2ptrade/dispute_payment/' +id);
  }

  release_payment(id: any): Observable<any> {
    return this.http.get<any>(this.baseUrl + 'auth/p2ptrade/release_payment/'+id);
  }


  alltransaction(type): Observable<Array<Transaction>> {
    return this.http.get<Array<Transaction>>(this.baseUrl + 'auth/system/transactions?type=' + type);
  }
  masterotp(user: User): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.baseUrl + 'auth/system/masterotp', user);
  }
  withdraw_payment(user: User): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.baseUrl + 'auth/system/withdraw_payment', user);
  }




  submit_withdraw(data: any): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.baseUrl + 'auth/system/submit_withdraw', data);
  }


  getOTP( ): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.baseUrl + 'auth/getOTP');
  }


  
  
  add_user(user: User): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.baseUrl + 'auth/system/add_user', user);
  }
  transactions_deposits(type): Observable<Array<Transaction>> {
    return this.http.get<Array<Transaction>>(this.baseUrl + 'auth/system/transactions_deposits?type=' + type);
  }
  unlock_withdraw_otpaddress(): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.baseUrl + 'auth/system/unlock_withdraw_otpaddress');
  }

  verifyotpwithdraw_address(user: User): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.baseUrl + 'auth/system/verifyotpwithdraw_address', user);
  }

  getRatesV2(): Observable<any> {
    return this.http.get<any>(this.baseUrl + 'erate_v2');
  }
  bxcoinlist(): Observable<any> {
    return this.http.get<any>(this.baseUrl + 'auth/system/bxcoinlist');
  }

  searchaddress(user: User): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.baseUrl + 'auth/system/searchaddress', user);
  }
  setotp(user: User): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.baseUrl + 'auth/system/setotp', user);
  }

  transactions_widthdraws(type): Observable<Array<Transaction>> {
    return this.http.get<Array<Transaction>>(this.baseUrl + 'auth/system/transactions_widthdraws?type=' + type);
  }
  bxcoinaddress(user: User): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.baseUrl + 'auth/system/bxcoinaddress', user);
  }

  changepassword(user: User): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.baseUrl + 'auth/changepassword', user);
  }


  set_user_password(user: User): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.baseUrl + 'auth/system/setuserpassword', user);
  }
  updatepaircurrency(user: User): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.baseUrl + 'auth/system/updatepaircurrency', user);
  }

  updatecurrency(user: User): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.baseUrl + 'auth/system/updatecurrency', user);
  }

  alltransactionbyid(id: number): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.baseUrl + 'auth/system/usertransactionbyid?id=' + id);
  }

  users_depositbyid(user_id: number): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.baseUrl + 'auth/system/users_depositbyid?id=' + user_id);
  }


  users_loginhistorybyid(user_id: number): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.baseUrl + 'auth/system/users_loginhistorybyid?id=' + user_id);
  }

  users_transactionbyid(user_id: number): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.baseUrl + 'auth/system/users_transactionbyid?id=' + user_id);
  }

  users_notificationbyid(user_id: number): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.baseUrl + 'auth/system/users_notificationbyid?id=' + user_id);
  }

  users_withdrawbyid(user_id: number): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.baseUrl + 'auth/system/users_withdrawbyid?id=' + user_id);
  }

  user_details(user_id: number): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.baseUrl + 'auth/system/user_details?id=' + user_id);
  }
  edit_currency(id: number): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.baseUrl + 'auth/system/edit_currency?id=' + id);
  }


  USDTtbalance(address: string): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.baseUrl + 'auth/system/USDTtbalance?address=' + address);
  }
  currencyUSDTt(): Observable<any> {
    return this.http.get<any>(this.baseUrl + 'auth/system/currencyUSDTt');
  }
  send_payment(user: User): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.baseUrl + 'auth/system/send_payment', user);
  }



  addcurrency(user: User): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.baseUrl + 'auth/system/addcurrency', user);
  }
  currencylist(): Observable<Array<Transaction>> {
    return this.http.get<Array<Transaction>>(this.baseUrl + 'auth/system/currencylist');
  }


  
  wallet_balance(): Observable<any> {
    return this.http.get<any>(this.baseUrl + 'auth/wallet_balance');
  }


  rate(id: number): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.baseUrl + 'auth/system/rate?id=' + id);
  }

  payment_user(user: User): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.baseUrl + 'auth/system/payment_user', user);
  }

  withdraw_address(user: User): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.baseUrl + 'auth/system/withdraw_address', user);
  }
  lock_withdraw_address(): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.baseUrl + 'auth/system/lock_withdraw_address');
  }

  payment_otp(): Observable<Array<Transaction>> {
    return this.http.get<Array<Transaction>>(this.baseUrl + 'auth/system/payment_otp');
  }

  edit_paircurrency(id: number): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.baseUrl + 'auth/system/edit_paircurrency?id=' + id);
  }

  widthdraws_details(id: number): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.baseUrl + 'auth/system/widthdraws_details?id=' + id);
  }

  delete_currency(id: number): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.baseUrl + 'auth/system/currencydelete?id=' + id);
  }

  addpaircurrency(user: User): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.baseUrl + 'auth/system/addpaircurrency', user);
  }
  currencypairlist(): Observable<Array<Transaction>> {
    return this.http.get<Array<Transaction>>(this.baseUrl + 'auth/system/currencypairlist');
  }

  notification(type: any): Observable<Array<Transaction>> {
    return this.http.get<Array<Transaction>>(this.baseUrl + 'auth/system/notification?type=' + type);
  }

  alldepositaddress(coin: string): Observable<Array<Transaction>> {
    return this.http.get<Array<Transaction>>(this.baseUrl + 'auth/system/alldepositaddress?coin=' + coin);
  }
  allethdepositaddress(): Observable<Array<Transaction>> {
    return this.http.get<Array<Transaction>>(this.baseUrl + 'auth/system/alldepositaddresseth');
  }


  login_history(type: any): Observable<Array<Transaction>> {
    return this.http.get<Array<Transaction>>(this.baseUrl + 'auth/system/login_history?type=' + type);
  }

  transactions_depositsbycoin(coin: string): Observable<Array<Transaction>> {
    return this.http.get<Array<Transaction>>(this.baseUrl + 'auth/system/transactions_depositsbycoin?coin=' + coin);
  }

  balancebycurrency(coin: any): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.baseUrl + 'auth/system/coinbalance/' + coin);
  }
  withdraw_payment_otp(): Observable<Array<Transaction>> {
    return this.http.get<Array<Transaction>>(this.baseUrl + 'auth/system/withdraw_payment_otp');
  }

  delete_paircurrency(id: number): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.baseUrl + 'auth/system/currencypairdelete?id=' + id);
  }
  postupdatemembership(data: any): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.baseUrl + 'auth/postupdatemembership', data);
  }

  addplan(user: User): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.baseUrl + 'auth/system/add_plan', user);
  }

  receive_payment(user: User): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.baseUrl + 'auth/system/receive_payment', user);
  }



  getuserById(): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.baseUrl + 'auth/me');
  }


  profile_enable(): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.baseUrl + 'auth/enableg2f');
  }
  withdrawremark(user: User): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.baseUrl + 'auth/system/withdrawremark', user);
  }

  authcode(user: User): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.baseUrl + 'auth/enableg2f', user);
  }

  get_client_ip(): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.baseUrl + 'auth/get_client_ip');
  }
  balance(): Observable<any> {
    return this.http.get<any>(this.baseUrl + 'auth/system/Balance');
  }

  updateProfile(user: User): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.baseUrl + 'auth/updateprofile', user);
  }

  updatedocument(document: Document): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.baseUrl + 'auth/postkycnew', document);
  }


  getUsers(): Observable<Array<User>> {
    return this.http.get<Array<User>>(this.baseUrl + 'auth/system/users');
  }

  getUseremails(): Observable<Array<User>> {
    return this.http.get<Array<User>>(this.baseUrl + 'auth/admin/system/usersemails');
  }


  getKYCUsers(): Observable<Array<User>> {
    return this.http.get<Array<User>>(this.baseUrl + 'auth/system/kyc_users');
  }


  getNonKYCUsers(): Observable<Array<User>> {
    return this.http.get<Array<User>>(this.baseUrl + 'auth/system/non_kyc_users');
  }


  getDeleteUsers(): Observable<Array<User>> {
    return this.http.get<Array<User>>(this.baseUrl + 'auth/system/delete_user_account_request');
  }


  getmembership(): Observable<Array<User>> {
    return this.http.get<Array<User>>(this.baseUrl + 'auth/system/membership');
  }

  getlogo(): Observable<Array<User>> {
    return this.http.get<Array<User>>(this.baseUrl + 'getlogo');
  }




  postlogo(data: any): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.baseUrl + 'auth/' + 'postlogo', data);
  }



  changeStatus(id: number): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.baseUrl + 'auth/' + 'changeStatus?id=' + id);

  }

  deleteaccountstatus(id: number): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.baseUrl + 'auth/' + 'deleteaccount?id=' + id);

  }


  deleteplan(id: number): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.baseUrl + 'auth/' + 'deleteplan?id=' + id);

  }

  accountStatus(id: number): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.baseUrl + 'auth/' + 'accountStatus?id=' + id);

  }
  balancecurrencylistbyid(id: number): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.baseUrl + 'auth/system/' + 'balancecurrencylistbyid?id=' + id);

  }
  balancecurrencylist(): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.baseUrl + 'auth/system/' + 'currencybalance');

  }
  notificationfundStatus(): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.baseUrl + 'auth/' + 'notificationfundStatus');

  }
  changecurrency(id: any, status: any): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.baseUrl + 'auth/system/changecurrency?id=' + id + '&status=' + status);
  }
  widthdraws_approve(id: any, status: any): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.baseUrl + 'auth/system/widthdraws_approve?id=' + id + '&status=' + status);
  }

  currencybalance(userid: any, currency: any): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.baseUrl + 'auth/system/usercurrencybalance?userid=' + userid + '&currency=' + currency);
  }


  changecurrencypair(id: any, status: any): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.baseUrl + 'auth/system/changecurrencypair?id=' + id + '&status=' + status);
  }
  activecurrencylist(): Observable<Array<User>> {
    return this.http.get<Array<User>>(this.baseUrl + 'auth/system/activecurrencylist');
  }
  //exchange

  book_order(user: User, type: any): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.baseUrl + 'exchange/book_order/' + type, user);
  }
  buyorder_list(c1: any, c2: any, buy: any): Observable<Array<Transaction>> {
    return this.http.get<Array<Transaction>>(this.baseUrl + 'exchange/orderbookings/' + buy + '/' + c1 + '/' + c2);
  }
  sellorder_list(c1: any, c2: any, sell: any): Observable<Array<Transaction>> {
    return this.http.get<Array<Transaction>>(this.baseUrl + 'exchange/orderbookings/' + sell + '/' + c1 + '/' + c2);
  }
  tradehistory(c1: any, c2: any): Observable<Array<Transaction>> {
    return this.http.get<Array<Transaction>>(this.baseUrl + 'exchange/allorderhistory' + '/' + c1 + '/' + c2);
  }
  completedorderhistory(c1: any, c2: any, completed: any): Observable<Array<Transaction>> {
    return this.http.get<Array<Transaction>>(this.baseUrl + 'orderhistory1/' + completed + '/' + c1 + '/' + c2);
  }
  pendingorderhistory(c1: any, c2: any, pending: any): Observable<Array<Transaction>> {
    return this.http.get<Array<Transaction>>(this.baseUrl + 'orderhistory1/' + pending + '/' + c1 + '/' + c2);
  }

  delete_order(id: number): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.baseUrl + 'deleteorder?id=' + id);
  }
  sendfund(user: User): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.baseUrl + 'wallet/sendFund', user);
  }




  balancehistory(): Observable<Array<Transaction>> {
    return this.http.get<Array<Transaction>>(this.baseUrlauth + 'balance');
  }





  ticker(): Observable<Array<Transaction>> {
    return this.http.get<Array<Transaction>>(this.baseUrl + 'exchange/tickers');
  }



  pairrate(c1: any, c2: any): Observable<ApiResponse> {
    const url = this.baseUrl + 'exchange/rate/' + c1 + '/' + c2;


    console.log(url);
    console.log(c1);
    return this.http.get<ApiResponse>(url);
  }




  // exchange
  getplan(id: any): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.baseUrl + 'auth/getplan?id=' + id);

  }



  changelogin(id: any): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.baseUrl + 'auth/admin/changelogin?id=' + id);

  }

  deleteg2f(email: any): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.baseUrl + 'auth/admin/deleteg2f?email=' + email);

  }
  deleteUser(id: any): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.baseUrl + 'auth/admin/deleteUser?id=' + id);

  }
  reset_api_key(): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.baseUrl + 'auth/reset_api_key');

  }

  networkfee(): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.baseUrl + 'v1/networkfee');

  }
}
