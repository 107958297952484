import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AuthService } from '../auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  @ViewChild('registerForm', { static: true }) registerForm: NgForm;
  logo;
  constructor(
    private authService: AuthService,
    private router: Router
  ) { }

  ngOnInit() {
    localStorage.clear();
    this.authService.getlogo()
      .subscribe(data => {
        console.log("logo", data['logo']);
        this.logo = data['logo'];

      });
  }


  reister() {
    this.authService
      .register(this.registerForm.value)
      .subscribe((data) => {
        // console.log(data.message);
        //alert(data.message);
        localStorage.setItem('token', data['token']);


        

        window.localStorage.removeItem("roll");
        window.localStorage.setItem("roll", data['user']['roll']);

        
        this.router.navigate(['/home']);
      });
  }

}
