
import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { JobService } from 'src/app/component/job.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent {
  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private _snackBar: MatSnackBar,
    private jobService: JobService) { }



  ngOnInit() {



    this.get_self_notifications();




  }

  get_self_notifications() {
    this.jobService.get_self_notifications()
      .subscribe(res => {

        this.notifications = res.data.notifications;


      });

  }

  sendtolink(link: any) {

    this.router.navigate([link]);

  }
  notifications: any;
}

