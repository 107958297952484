<div class="          block1 ">
   <div class="c ard-body">

      <mat-card>
         <mat-card-content>
            <mat-tab-group mat-align-tabs="start">
               <mat-tab label="Buy GEMS" class="tabs">
                  <form [formGroup]="orderForm">
                     <table class="    ">
                        <tr class="tr">
                           <td>

                              <select class="form-control       " name="order_type" [(value)]="selected"
                                 formControlName="order_type">

                                 <option value="limit">Limit</option>
                                 <option value="market" selected>Market</option>
                                 <option value="stoplimit">Stop Limit</option>

                              </select>




                           </td>
                           <td>
                           </td>
                        </tr>
                        <tr class="tr">
                           <td>
                              <label class="label">Price {{c1}}</label>


                              <input type="text" (keyup)="onKey($event)" class="form-control    " id="c1_value"
                                 formControlName="c1_value" name="c1_value" value="{{rate1_print}}">


                              <input type="hidden" class="form-control" id="c1_coin" formControlName="c1_coin"
                                 name="c1_coin" value="{{c1}}">


                           </td>
                           <td>
                              <label class="label"> Amount {{c2}}</label>


                              <input type="text" class="form-control     " id="c2_value" (keyup)="onKey1($event)"
                                 formControlName="c2_value" name="c2_value" value="{{rate2_print}}">


                              <input type="hidden" class="form-control" id="c2_coin" formControlName="c2_coin"
                                 name="c2_coin" value="{{c2}}">


                           </td>
                        </tr>
                        <tr class="tr">
                           <td colspan="2">

                              <button (click)="createTrade('buy')"
                                 class="btn btn-success   btn-block  mt-2   w-100  ">Buy</button>
                           </td>



                        </tr>
                     </table>
                  </form>
               </mat-tab>
               <mat-tab label="Sell GEMS" class="tabs        ">
                  <form [formGroup]="orderForm">
                     <table>
                        <tr class="tr">
                           <td>




                              <select class="form-control       " name="order_type" formControlName="order_type"
                                 [(value)]="selected" required>
                                 <option value="limit">Limit</option>
                                 <option value="market">Market</option>
                                 <option value="stoplimit">Stop Limit</option>
                              </select>


                           </td>
                           <td>
                           </td>
                        </tr>
                        <tr class="tr">
                           <td>
                              <label class="label">Price {{c1}}</label>
                              <input type="text" (keyup)="onKey($event)" class="form-control    " id="c1_value"
                                 formControlName="c1_value" name="c1_value" value="{{rate1_print}}">
                              <input type="hidden" class="form-control" id="c1_coin" formControlName="c1_coin"
                                 name="c1_coin" value="{{c1}}">
                           </td>
                           <td>
                              <label class="label"> Amount {{c2}}</label>
                              <input type="text" class="form-control     " id="c2_value" (keyup)="onKey1($event)"
                                 formControlName="c2_value" name="c2_value" value="{{rate2_print}}">
                              <input type="hidden" class="form-control" id="c2_coin" formControlName="c2_coin"
                                 name="c2_coin" value="{{c2}}">
                           </td>
                        </tr>
                        <tr class="tr">
                           <td colspan="2">

                              <button (click)="createTrade('sell')"
                                 class="btn btn-danger  w-100    mt-2  ">Sell</button>
                           </td>
                        </tr>
                     </table>
                  </form>
               </mat-tab>
            </mat-tab-group>
         </mat-card-content>
      </mat-card>

   </div>
</div>