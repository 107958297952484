import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router, ActivatedRoute, Params, ParamMap } from "@angular/router";
import { JobService } from '../../job.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { switchMap } from 'rxjs/operators';
import { User } from 'src/app/models/user.model'; 

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
//import { inherits } from 'util';

@Component({
  selector: 'app-booking-form',
  templateUrl: './booking-form.component.html',
  styleUrls: ['./booking-form.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BookingFormComponent implements OnInit, OnChanges {
  @Input() pair; // decorate the property with @Input()
  orderForm: FormGroup;
  c1: any;
  c2: any;
  rate: any;
  rate2_print: any;

  rate1_print: any;

  selected = 'market';

  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private jobService: JobService,
    private _snackBar: MatSnackBar,
    private activatedRoute: ActivatedRoute) { }

  ngOnInit() {

    this.orderForm = this.formBuilder.group({
      order_type: ['market', Validators.required],
      c1_value: ['', Validators.required],
      c2_value: ['', Validators.required],
      c1_coin: ['', Validators.required],
      c2_coin: ['', Validators.required],
    });


    this.jobService.pairrate(this.c1, this.c2)
      .subscribe(data => {
        console.log("data", data['rate']);

        this.rate = data['rate'];


      });

  }

  ngOnChanges(changes: SimpleChanges) {

   

    this.c1 = this.pair.c1;
    this.c2 = this.pair.c2;

    // changes.prop contains the old and the new value...
    console.log(this.c1);
    console.log(changes);

    console.log(this.pair);
    console.log(this.c2);
    this.jobService.pairrate(this.c1, this.c2)
      .subscribe(data => {
        console.log("data", data['rate']);

        this.rate = data['rate'];


      });


  }

  createTrade(type) {

    console.log(this.orderForm.value);
    this.jobService.book_order(this.orderForm.value, type + "/" + this.c1 + "/" + this.c2)
      .subscribe(data => {
        this._snackBar.open("Order booked successfully", "Close", {
          duration: 2000,
          verticalPosition: 'top',
          horizontalPosition: 'center'
        });
        //  this.getdata();
      });
  }

  onKey(event: any) { // without type info


    //alert(this.rate);

    this.rate2_print = this.rate * event.target.value;


    this.orderForm.controls['c2_value'].setValue(this.rate2_print);



    this.orderForm.controls['c1_coin'].setValue(this.c1);

    this.orderForm.controls['c2_coin'].setValue(this.c2);


  }


  onKey1(event: any) { // without type info

    this.rate1_print = event.target.value / this.rate;
    //this.rate1_print = this.rate * event.target.value;
    this.orderForm.controls['c1_value'].setValue(this.rate1_print);
    this.orderForm.controls['c1_coin'].setValue(this.c1);
    this.orderForm.controls['c2_coin'].setValue(this.c2);
  }



}