 

import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { JobService } from 'src/app/component/job.service';

@Component({
  selector: 'app-p2p-trade-orders',
  templateUrl: './p2p-trade-orders.component.html',
  styleUrls: ['./p2p-trade-orders.component.scss']
})
export class P2pTradeOrdersComponent {
 
  trade_order_list_data:any;


  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private _snackBar: MatSnackBar,
    private jobService: JobService) { }



  ngOnInit() {



    this.get_self_trade_orders();




  }

  get_self_trade_orders() {

    this.jobService.get_self_trade_orders()
      .subscribe(res => {

        this.trade_order_list_data = res.data;


      });
  }


  sendto(id: any) {



    this.router.navigate(['p2p-trade-processing', id]);

  }
 

  


}


