import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { JobService } from 'src/app/component/job.service';

@Component({
  selector: 'app-create-trades',
  templateUrl: './create-trades.component.html',
  styleUrls: ['./create-trades.component.scss']
})
export class CreateTradesComponent {



  constructor(private formBuilder: FormBuilder, private router: Router,
    private jobService: JobService, private _snackBar: MatSnackBar) { }

  add_new_trade_Form: FormGroup;
  msg: any;
  ngOnInit() {

    this.add_new_trade_Form = this.formBuilder.group({

      title: ['', Validators.required],
      type: ['Sell', Validators.required],
      c1: ['', Validators.required],

      c2: ['', Validators.required],


      minimum: ['', Validators.required],
      maximum: ['', Validators.required],
      terms_and_conditions: ['', Validators.required],

      payment_window: ['', Validators.required],
      payment_method: ['', Validators.required],
      margin: ['', Validators.required]

    });

  }

  onSubmit() {
    this.jobService.add_new_trade(this.add_new_trade_Form.value)
      .subscribe(data => {
        this.msg = data['message'];
        this._snackBar.open(this.msg, "Close", {
          duration: 2000,

          verticalPosition: 'top',
          horizontalPosition: 'center'

        });

        this.router.navigate(['manage/trades']);
      });
  }


}

