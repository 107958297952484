<h5 class="card-title"> Add Currency</h5>

<mat-card>
  <mat-card-content>

    <form [formGroup]="addForm" (ngSubmit)="onSubmit()">

      <div class="form-group">
        <label for="currency">Currency </label>
        <input type="text" formControlName="currency" placeholder="Currency" name="currency" class="form-control"
          id="currency">
      </div>




      <div class="form-group">
        <label for="symbol">Symbol </label>
        <input type="text" formControlName="symbol" placeholder="Symbol" name="symbol" class="form-control" id="symbol">
      </div>

      <div class="form-group">
        <label for="deposit_charge">Depsoit Charge </label>
        <input type="number" formControlName="deposit_charge" placeholder="Depsoit Charge" name="deposit_charge"
          class="form-control" id="deposit_charge">
      </div>


      <div class="form-group">
        <label for="withdraw_charge">Withdraw Charge </label>
        <input type="number" formControlName="withdraw_charge" placeholder="Withdraw Charge" name="withdraw_charge"
          class="form-control" id="withdraw_charge">
      </div>

      <div class="form-group">
        <label for="admin_wallet_address">Admin Wallet Address</label>
        <input type="text" formControlName="admin_wallet_address" placeholder="Admin Wallet Address"
          name="admin_wallet_address" class="form-control" id="admin_wallet_address">
      </div>


      <ngx-dropzone (change)="onSelect($event,'icon')">
        <ngx-dropzone-label>Drop the coin icon here!</ngx-dropzone-label>

        <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of files" [file]="f"
          [removable]="true" (removed)="onRemove(f)">
          <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
        </ngx-dropzone-image-preview>



      </ngx-dropzone>

      <br>
      <button class="btn btn-primary btn-sm  ">Add</button>







    </form>


  </mat-card-content>
</mat-card>
<mat-card>
  <mat-card-content>
    <table class="table ">
      <thead>
        <tr>


          <th class="hidden">ID</th>

          <th>Currency</th>

          <th>Symbol</th>
          <th>Deposit Charge</th>
          <th>Withdraw Charge</th>
          <th>Status</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let t of currency index as i;">

          <td>{{i+1}}</td>
          <td>{{t.currency}}</td>

          <td>{{t.symbol}}</td>

          <td>{{t.deposit_charge}}</td>
          <td>{{t.withdraw_charge}}</td>
          <td>{{t.status}}</td>

          <td>
            <div  class="btn  "    style="color:green;" (click)="edit_currency(t.id)">Edit
            </div>


            <div   class="btn  "  style="color:red;" (click)="delete_currency(t.id)">Remove</div>






            <span *ngIf="t.status=='active'">
              <button class="btn  float-right  btn-danger btn-sm"
                (click)="changecurrency(t.id,'inactive')">Inactive</button>
            </span>

            <span *ngIf="t.status=='inactive'"> <button class="btn  float-right  btn-success btn-sm"
                (click)="changecurrency(t.id,'active')">Active</button>
            </span>
          </td>


        </tr>
      </tbody>
    </table>

  </mat-card-content>
</mat-card>