import { Component } from '@angular/core';

@Component({
  selector: 'app-trade-review',
  templateUrl: './trade-review.component.html',
  styleUrls: ['./trade-review.component.scss']
})
export class TradeReviewComponent {

}
