import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router, ActivatedRoute, Params, ParamMap } from "@angular/router";
import { JobService } from '../../job.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { switchMap } from 'rxjs/operators';
import { User } from 'src/app/models/user.model';
//import { createChart } from 'lightweight-charts';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
//import { inherits } from 'util';

@Component({
  selector: 'app-order-history',
  templateUrl: './order-history.component.html',
  styleUrls: ['./order-history.component.css']
})
export class OrderHistoryComponent implements OnInit {
  tradehistory: any;
  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private jobService: JobService,
    private _snackBar: MatSnackBar,
    private activatedRoute: ActivatedRoute) { }
  buyorder: any;

  sellorder: any;
  c1: any; c2: any;

  ngOnInit() {
    this.c1 = "BTC";
    this.c2 = "USD";

    this.jobService.buyorder_list(this.c1, this.c2, "buy")
      .subscribe(data => {
        this.buyorder = data;

      });



    //sell order history
    this.jobService.sellorder_list(this.c1, this.c2, "sell")
      .subscribe(data => {
        this.sellorder = data;

      });
      this.jobService.tradehistory(this.c1, this.c2)
      .subscribe(data => {
            this.tradehistory = data;

      });

  }




}
