<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Delete Account</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <label>Deleting Your account will remove all of your information</label>
    <label>from our database. This cannot be undone</label>
    <div class="modal-footer">
      <button class="btn btn-danger btn-sm" (click)="sendtostatus('Cross click')">Delete</button>

    </div>

  </div>

</ng-template>

<ng-template #content1 let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Change Profile </h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">


    <ngx-dropzone (change)="onSelect($event,'profile_pic')">
      <ngx-dropzone-label>Drop the profile here!</ngx-dropzone-label>

      <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of files" [file]="f"
        [removable]="true" (removed)="onRemove(f)">
        <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
      </ngx-dropzone-image-preview>
    </ngx-dropzone>

    <div class="modal-footer">
      <button class="btn" (click)="sendtostatus('Cross click')">Update</button>

    </div>

  </div>

</ng-template>




<div class="row">



  <div class="col-md-12">
    <mat-card>
      <mat-card-content>

        <h5 class="card-title">User Profile</h5>
        <p class="card-text">Please Contact Account manager to update your profile</p>
        <a class="float-right" style="color:white" (click)="open1(content1)">Change Profile</a>
        <i class="fa fa-user float-right" style="color:white;"></i>

        <form [formGroup]="editForm" (ngSubmit)="onSubmit()">




          <figure class="figure" style="padding:30px;">
            <img src="{{editForm.value.profile_pic}}" class="img-thumbnail" width="200px" height="200px">

          </figure>


          <input type="hidden" name="api_key" id="api_key">



          <div class="form-group">
            <label for="first_name">First Name:</label>

            <input type="text" formControlName="first_name" placeholder="First Name" name="first_name"
              class="form-control" id="first_name">

          </div>

          <div class="form-group">
            <label for="last_name">Last Name</label>
            <input formControlName="last_name" placeholder="Last Name" name="last_name" class="form-control"
              id="last_name">
          </div>


          <div class="form-group">
            <label for="email">Email</label>
            <input formControlName="email" placeholder="Email" name="email" class="form-control" id="email"
              readonly=true>
          </div>


          <div class="form-group">
            <label for="mobile">Mobile No</label>
            <input formControlName="mobile" placeholder="Mobile No" name="mobile" class="form-control" id="mobile">
          </div>

          <div class="form-group">
            <label for="country">Country</label>
            <input formControlName="country" placeholder="Country" name="country" class="form-control" id="country"
              readonly=true>
          </div>

          <div class="form-group">
            <label for="state">State</label>
            <input formControlName="state" placeholder="State" name="state" class="form-control" id="state">
          </div>




          <div class="form-group">
            <label for="address1">Address Line 1:</label>
            <textarea rows="4" cols="50" formControlName="address1" placeholder="Address Line 1" name="address1"
              class="form-control" id="address1">

</textarea>




          </div>

          <div class="form-group">
            <label for="address2">Address Line 2:</label>
            <textarea rows="4" cols="50" formControlName="address2" placeholder="Address Line 2" name="address2"
              class="form-control" id="address2">

</textarea>




          </div>
          <div class="form-group">
            <label for="gender">Gender</label>


            <select class="form-control" formControlName="gender">

              <option value="noanswer">
                No Answer
              </option>



              <option value="male">
                Male
              </option>

              <option value="female">
                Female
              </option>


            </select>


          </div>





          <span *ngIf="editForm.value.account_status==1">

            <a style="color:green">Delete Request Submitted</a>

          </span>


          <span *ngIf="editForm.value.account_status==0">
            <a (click)="open(content)" style="color:blue">Delete account? </a>
          </span>


          <button mat-button color="primary" class="btn float-right">Update</button>

        </form>
      </mat-card-content>
    </mat-card>
  </div>



</div>