

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router, ActivatedRoute, Params, ParamMap } from "@angular/router";

import { MatSnackBar } from '@angular/material/snack-bar';
import { switchMap } from 'rxjs/operators';
import { JobService } from 'src/app/component/job.service';


@Component({
  selector: 'app-trade-show',
  templateUrl: './trade-show.component.html',
  styleUrls: ['./trade-show.component.scss']
})
export class TradeShowComponent {



  id: any;
  trade: any;


  constructor(private formBuilder: FormBuilder,
    private router: Router, private _snackBar: MatSnackBar,
    private activatedRoute: ActivatedRoute,
    private jobService: JobService) { }




  ngOnInit() {
    this.id = this.activatedRoute.snapshot.params.id;


    this.jobService.trade_details(this.id)
      .subscribe(res => {

        this.trade = res.data;





      });

  }

  edit_trade(id: any) {



    this.router.navigate(['edit_trade', id]);

  }
  update_status(id: any, status: any) {

    this.jobService.update_status(id, status)
      .subscribe(res => {

        this._snackBar.open(res.message, "Close", {
          duration: 2000,

          verticalPosition: 'top',
          horizontalPosition: 'center'

        });

      });
  }



}

