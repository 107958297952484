import { AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from '@angular/router';
import { Trade } from 'src/app/models/trade';
import { JobService } from '../../job.service';

@Component({
  selector: 'app-book-order',
  templateUrl: './book-order.component.html',
  styleUrls: ['./book-order.component.scss']
})
export class BookOrderComponent implements OnChanges, OnInit, AfterViewInit {


  @Input() trade: Trade;
  orderForm: FormGroup;


  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private jobService: JobService,
    private _snackBar: MatSnackBar,
    private activatedRoute: ActivatedRoute) { }


  ngAfterViewInit() {


    this.setforms();

  }

  c1: any;
  c2: any;
  ngOnInit() {


    this.setforms();
  }


  ngOnChanges(changes: SimpleChanges) {



    this.setforms();

  }

  setforms() {


    this.orderForm = this.formBuilder.group({

      c1_value: ['', Validators.required],
      c2_value: ['', Validators.required],
      c1: ['', Validators.required],
      c2: ['', Validators.required],

      type: ['', Validators.required],
      message: ['', Validators.required],
      trade_id: [1, Validators.required],

      
    });


    if (this.trade == undefined)
      return "";
 



    this.orderForm.controls['trade_id'].setValue(this.trade.id);

    this.orderForm.controls['c1'].setValue(this.trade.c1);

    this.orderForm.controls['c2'].setValue(this.trade.c2);

  }
  start_p2p_trade() {

    const type = "buy";
    this.orderForm.controls['type'].setValue(type);


    this.jobService.start_p2p_trade(this.orderForm.value)
      .subscribe(res => {



        this._snackBar.open(res.message, "Close", {
          duration: 2000,
          verticalPosition: 'top',
          horizontalPosition: 'center'
        });



        if (Number(res.id)>0)
        this.router.navigate(['p2p-trade-processing', res.id]);


      });
  }

  onKey(event: any) { // without type info


    //alert(this.rate);

    let c2_value = this.trade.rate * event.target.value;


    //this.orderForm.controls['c2_value'].setValue(this.trade.c2);




    this.orderForm.controls['c2_value'].setValue(c2_value);


  }



  onKey1(event: any) { // without type info

    let c1_value = event.target.value /  this.trade.rate ;






    this.orderForm.controls['c1_value'].setValue(c1_value);


  }

}
