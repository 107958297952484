import { Component, OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { Router } from "@angular/router";
import { Transaction } from "src/app/models/transaction.model";
import { JobService } from "../../job.service";





@Component({
  selector: 'app-buy-trade',
  templateUrl: './buy-trade.component.html',
  styleUrls: ['./buy-trade.component.scss']

})
export class BuyTradeComponent implements OnInit {
  dataSource;

  columnsToDisplay = [];
  expandedElement: null;

  trade_list_data: any;


  loading: boolean = true;

  testCount: any;

  constructor(private formBuilder: FormBuilder, private router: Router, private jobService: JobService) { }

  ngOnInit() {


    // user will buy the bitcoin from the add where trader is selling so sell type add are here
    this.trade_list("Sell");




  }

  trade_list(type) {

    this.jobService.get_trade_lists(type)
      .subscribe(res => {

        this.trade_list_data = res.data;

        this.loading = false;
      });
  }


  start_trade(id: any) {



    this.router.navigate(['trade_detail', id]);

  }

  view_trader_profile(user_id: any) {

    this.router.navigate(['trader_profile', user_id]);

  }
}
