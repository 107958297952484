<mat-card>
  <mat-card-content>
    <h5>Address Generated</h5>



    You selected coin : <b>{{receive_coin}}</b>

    <br />
    <br />
    <br />
    Your receiving Address :
    {{receive_address}}  


    <br />
    <br />

    <img src="https://api.qrserver.com/v1/create-qr-code/?size=250x250&data={{receive_address}}"
      class="img-thumbnail" />
  </mat-card-content>
</mat-card>