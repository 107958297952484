import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from '@angular/router';
import { JobService } from '../../job.service';

@Component({
  selector: 'app-p2p-trade-processing',
  templateUrl: './p2p-trade-processing.component.html',
  styleUrls: ['./p2p-trade-processing.component.scss']
})
export class P2pTradeProcessingComponent {
  id: any;
  trade_details: any;
  trade_info: any;
  trade_order_info: any;
  constructor(private formBuilder: FormBuilder,
    private router: Router, private _snackBar: MatSnackBar,
    private activatedRoute: ActivatedRoute,
    private jobService: JobService) { }



  ngOnInit() {
    this.id = this.activatedRoute.snapshot.params.id;


    this.jobService.trade_order_details(this.id)
      .subscribe(res => { 
        this.trade_order_info = res.data;
 
      });

  }



  release_payment(id: any) {


    this.jobService.release_payment(id)
      .subscribe(res => {


        this._snackBar.open(res.message, "Close", {
          duration: 2000,
          verticalPosition: 'top',
          horizontalPosition: 'center'
        });

      });

  }

  dispute_payment(id: any) {



    this.jobService.dispute_payment(id)
      .subscribe(res => {


        this._snackBar.open(res.message, "Close", {
          duration: 2000,
          verticalPosition: 'top',
          horizontalPosition: 'center'
        });



      });

  }  
  make_payment_btn(id: any) {



    this.jobService.make_payment(id)
      .subscribe(res => {


        this._snackBar.open(res.message, "Close", {
          duration: 2000,
          verticalPosition: 'top',
          horizontalPosition: 'center'
        });



      });

  }  
  
  
  cancel_order(id: any) {



     this.jobService.cancel_trade(id)
       .subscribe(res => {


       this._snackBar.open(res.message, "Close", {
           duration: 2000,
         verticalPosition: 'top',
        horizontalPosition: 'center'
          });



  
          });

  }


} 