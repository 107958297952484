 

 <mat-card>
  <mat-card-content>



        <h1 class="card-title">Change Password</h1>



        <form [formGroup]="addForm" (ngSubmit)="onSubmit()">

          <div class="form-group">
            <label for="current_password">Current Password</label>
            <input type="text" formControlName="current_password" placeholder="Current Password"
              name="current_passwordcurrent_password" class="form-control" id="current_password">
          </div>


          <div class="form-group">
            <label for="new_password">New password</label>
            <input formControlName="new_password" placeholder="New password" name="new_password" class="form-control"
              id="new_password">
          </div>


          <div class="form-group">
            <label for="confirm_password">Confirm Password</label>
            <input formControlName="confirm_password" placeholder="Confirm Password" name="confirm_password"
              class="form-control" id="confirm_password">
          </div>



          <button class="btn  w-100">Submit request</button>



        </form>


        
  </mat-card-content>
</mat-card>