<mat-card>



    <mat-card-content>


System Notifications


        <table class=" table ">
           
            <tbody>
                <tr class="tr" *ngFor="let q of notifications">

                    <td (click)="sendtolink(q.data.link)">{{q.data.message}}</td>

                    <td (click)="sendtolink(q.data.link)">{{q.timeAgo  }}</td>
                    

                </tr>
            </tbody>
        </table>


    </mat-card-content>
</mat-card>