<div *ngIf="trade" class="row">
    <div class="col-md-12 ">
        <div class="saksh_title">User {{trade.name}} wishes to trade for bitcoins to you. </div>

    </div>


    <div class="col-md-6 mt-4">


        <div class="row   mb-2">

            <div class="col-md-6">
                Price:

            </div>
            <div class="col-md-6">
                {{trade.rate}} {{trade.c1 }} {{trade.c2 }}


            </div>
        </div>
        <div class="row   mb-2">



            <div class="col-md-6">



                Payment method:


            </div>
            <div class="col-md-6">


                {{trade.payment_method}}


            </div>
        </div>
        <div class="row   mb-2">


            <div class="col-md-6">


                User:
            </div>
            <div class="col-md-6">


                {{trade.user_id}}

                (feedback score 100 %, see feedback)


            </div>
        </div>


        <div class="row   mb-2">

            <div class="col-md-6">


                Trade limits:
            </div>
            <div class="col-md-6">
                {{trade.minimum}} - {{trade.maximum}}


            </div>

        </div>



        <div class="row   mb-2">




            <div class="col-md-6">


                Location:
            </div>
            <div class="col-md-6">
                {{trade.location}}

            </div>
        </div>

        <div class="row   mb-5">
            <div class="col-md-6">


                Payment window:

            </div>
            <div class="col-md-6">
                {{trade.payment_window}}


            </div>


        </div>
        <app-book-order [trade]="(trade)"> </app-book-order>
    </div>

    <div class="col-md-6">



        {{trade.terms_and_conditions}}

        <hr />

        <a [routerLink]="['/report_this_advertisement']"> Report this advertisement</a>

    </div>


</div>