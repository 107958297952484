import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";

import { JobService } from '../../job.service';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-addressform',
  templateUrl: './addressform.component.html',
  styleUrls: ['./addressform.component.css']
})
export class AddressformComponent implements OnInit {


  addForm: FormGroup;
 addresslist;

  constructor(private formBuilder: FormBuilder,private router: Router, private jobService: JobService,private _snackBar: MatSnackBar) { }

  ngOnInit() {



    //let v= window.localStorage.getItem("code");

this.addForm = this.formBuilder.group({
      id: [],
      address: ['', Validators.required] 
    });

  }



  onSubmit() {

    this.jobService.searchaddress(this.addForm.value)
      .subscribe( data => {
  console.log(data);
this.addresslist=data;
 

      });
  }

}
