// import { BrowserModule } from '@angular/platform-browser';
// import { NgModule } from '@angular/core';
//import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { AppComponent } from './app.component';
// import { HomeComponent } from './component/home/home.component';
import { LoginComponent } from './authentication/login/login.component';
// import { RegisterComponent } from './authentication/register/register.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
//  import { ReactiveFormsModule, FormsModule } from '@angular/forms';
// import { DropdownDirective } from './component/shared/naviagtion/dropdown.directive';
// import { CollapseDirective } from './component/shared/naviagtion/collapse.directive';
//import { TimeAgoPipe } from 'time-ago-pipe';
import { AppRoutingModule } from './app-routing.module';
import { AuthService } from './authentication/auth.service';
//import { ToastrModule } from 'ngx-toastr';
// import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { JwtInterceptorService } from './jwt-interceptor.service';
// import { NaviagtionComponent } from './component/shared/naviagtion/naviagtion.component';
// import { FooterComponent } from './component/shared/footer/footer.component';

import { JobService } from './component/job.service';
import { ListUserComponent } from './component/wallet/user/list-user/list-user.component';
//import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
//import { NgbModule } from '@ng-bootstrap/ng-bootstrap';


//import { NgxSpinnerModule } from "ngx-spinner";

import { MatSnackBar } from '@angular/material/snack-bar';

// import { MembershipUpgradeComponent } from './component/wallet/membership-upgrade/membership-upgrade.component';

import { TransactionComponent } from './component/wallet/transaction/transaction.component';
// import { WithdrawComponent } from './component/wallet/withdraw/withdraw.component';
// import { DepositComponent } from './component/wallet/deposit/deposit.component';
// import { UserDepositComponent } from './component/wallet/user-deposit/user-deposit.component';
// import { UserDepositAddressComponent } from './component/wallet/user-deposit-address/user-deposit-address.component';

//import { NgxDropzoneModule } from 'ngx-dropzone';

import {TimeAgoPipe} from 'time-ago-pipe';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatStepperModule } from '@angular/material/stepper';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { SystemComponent } from './component/exchange/system/system.component';



import { UserProfileComponent } from './component/wallet/user-profile/user-profile.component';
import { ChangePasswordComponent } from './component/wallet/change-password/change-password.component';
// import { GeneralSettingComponent } from './component/wallet/general-setting/general-setting.component';
// import { SubscriptionComponent } from './component/wallet/subscription/subscription.component';
// import { CronComponent } from './component/wallet/cron/cron.component';
// import { ConfigureEmailComponent } from './component/wallet/configure-email/configure-email.component';
import { BankAccountComponent } from './component/wallet/bank-account/bank-account.component';
// import { PaymentFormComponent } from './component/wallet/payment-form/payment-form.component';
// import { ProfileEnableComponent } from './component/wallet/profile-enable/profile-enable.component';
// import { MembershipComponent } from './component/wallet/membership/membership.component';
// import { AddPlanComponent } from './component/wallet/add-plan/add-plan.component';
import { DashboardComponent } from './component/wallet/dashboard/dashboard.component';
// import { AdminProfileComponent } from './component/wallet/admin-profile/admin-profile.component';
// import { IncomingNotificationComponent } from './component/wallet/incoming-notification/incoming-notification.component';
// import { DeleteUserAccountRequestComponent } from './component/wallet/user/delete-user-account-request/delete-user-account-request.component';

import { UserDashboardComponent } from './component/wallet/user-dashboard/user-dashboard.component';
// import { TradingComponent } from './component/wallet/trading/trading.component';
// import { PairchangeComponent } from './component/wallet/pairchange/pairchange.component';
// import { CurrencyComponent } from './component/wallet/currency/currency.component';
// import { PairManagmentComponent } from './component/wallet/pair-managment/pair-managment.component';

// import { KycDocumentComponent } from './component/wallet/kyc-document/kyc-document.component'
// import { NonKycUsersComponent } from './component/wallet/user/non-kyc-users/non-kyc-users.component';
// import { KycUsersComponent } from './component/wallet/user/kyc-users/kyc-users.component';
// import { ConfirmPaymentComponent } from './component/wallet/confirm-payment/confirm-payment.component';
// import { SendfundComponent } from './component/wallet/sendfund/sendfund.component';
// import { UserWithdrawsComponent } from './component/wallet/user-withdraws/user-withdraws.component';
// import { ForgetComponent } from './authentication/forget/forget.component';
// import { ResetpasswordComponent } from './authentication/resetpassword/resetpassword.component';
// import { NotificationComponent } from './component/wallet/notification/notification.component';
// import { LoginHistoryComponent } from './component/wallet/login-history/login-history.component';
// import { EditPairComponent } from './component/wallet/edit-pair/edit-pair.component';
import { AllDepositAddressComponent } from './component/wallet/all-deposit-address/all-deposit-address.component';
// import { ForgetgoogleauthComponent } from './authentication/forgetgoogleauth/forgetgoogleauth.component';
// import { ResetgoogleauthComponent } from './authentication/resetgoogleauth/resetgoogleauth.component';
// import { EthAddressListComponent } from './component/wallet/eth-address-list/eth-address-list.component';
import { AddressformComponent } from './component/wallet/addressform/addressform.component';
// import { WithdrawAddressComponent } from './component/wallet/withdraw-address/withdraw-address.component';
// import { EditCurrencyComponent } from './component/wallet/edit-currency/edit-currency.component';
// //import { NgFlashMessagesModule } from 'ng-flash-messages';
import { WithdrawlistComponent } from './component/wallet/withdrawlist/withdrawlist.component';
// import { AdminWalletComponent } from './component/wallet/admin-wallet/admin-wallet.component';
// import { UserDashboardListComponent } from './component/wallet/user-dashboard-list/user-dashboard-list.component';
// import { BxcoinComponent } from './component/wallet/bxcoin/bxcoin.component';
// import { AddUsersComponent } from './component/wallet/add-users/add-users.component';
// import { BookingFormComponent } from './component/exchange/booking-form/booking-form.component';
// import { TickersComponent } from './component/exchange/tickers/tickers.component';
// import { SystemComponent } from './component/exchange/system/system.component';
import { OrderHistoryComponent } from './component/exchange/order-history/order-history.component';
import { BookingFormComponent } from './component/exchange/booking-form/booking-form.component';
import { TickersComponent } from './component/exchange/tickers/tickers.component';
import { DepositComponent } from './component/wallet/deposit/deposit.component';
import { LoginHistoryComponent } from './component/wallet/login-history/login-history.component';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UserDepositAddressComponent } from './component/wallet/user-deposit-address/user-deposit-address.component';
import { UserDepositComponent } from './component/wallet/user-deposit/user-deposit.component';
import { RegisterComponent } from './authentication/register/register.component';
import { BuyTradeComponent } from './component/trade/buy-trade/buy-trade.component';
import { SellTradeComponent } from './component/trade/sell-trade/sell-trade.component';
import { TradeDetailsComponent } from './component/trade/trade-details/trade-details.component';
import { DiscussionComponent } from './component/trade/discussion/discussion.component';
import { BookOrderComponent } from './component/trade/book-order/book-order.component';
import { P2pTradeProcessingComponent } from './component/trade/p2p-trade-processing/p2p-trade-processing.component';

import { TradesComponent } from './component/trade/manage/trades/trades.component';
import { CreateTradesComponent } from './component/trade/manage/create-trades/create-trades.component';
import { TradeShowComponent } from './component/trade/manage/trade-show/trade-show.component';
import { P2pTradeOrdersComponent } from './component/trade/p2p-trade-orders/p2p-trade-orders.component';
import { NotificationsComponent } from './component/notifications/notifications.component';
import { TraderProfileComponent } from './component/trade/trader-profile/trader-profile.component';
import { TradeReviewComponent } from './component/trade/trade-review/trade-review.component';
import { CurrencyComponent } from './component/wallet/currency/currency.component';
import { NgxDropzoneModule } from 'ngx-dropzone';

@NgModule({
  declarations: [


    // TimeAgoPipe,
    AppComponent,
    //HomeComponent,
    LoginComponent,
    RegisterComponent,
    // NaviagtionComponent,
    // DropdownDirective,
    // FooterComponent,

    // CollapseDirective,

    ListUserComponent,
    // UserDepositComponent,
    // UserDepositAddressComponent,
    // SendfundComponent,
    // UserWithdrawsComponent,
    TransactionComponent,
    // WithdrawComponent,
    DepositComponent,
    UserProfileComponent,
    ChangePasswordComponent,
    // GeneralSettingComponent,
    // SubscriptionComponent,
    // CronComponent,
    // ConfigureEmailComponent,
    BankAccountComponent,
    // PaymentFormComponent,
    // ProfileEnableComponent,
    // MembershipComponent,
    // AddPlanComponent,
    DashboardComponent,
    // AdminProfileComponent,
    // IncomingNotificationComponent,
    // DeleteUserAccountRequestComponent,

    UserDashboardComponent,

    // TradingComponent,

    // PairchangeComponent,

    // CurrencyComponent,
    // PairManagmentComponent,
    // KycDocumentComponent,
    // NonKycUsersComponent,
    // KycUsersComponent,
    // ConfirmPaymentComponent,
    // MembershipUpgradeComponent,
    // ForgetComponent,
    // ResetpasswordComponent,
    // NotificationComponent,
    LoginHistoryComponent,
    // EditPairComponent,
    AllDepositAddressComponent,
    // ForgetgoogleauthComponent,
    // ResetgoogleauthComponent,
    // EthAddressListComponent,
    AddressformComponent,
    // WithdrawAddressComponent,
    // EditCurrencyComponent,
    WithdrawlistComponent,
    // AdminWalletComponent,
    // UserDashboardListComponent,
    // BxcoinComponent,
    // AddUsersComponent,
    UserDepositAddressComponent,
    UserDepositComponent,

    CurrencyComponent,
    BookingFormComponent,
    TickersComponent,
    SystemComponent,
    OrderHistoryComponent,
    BuyTradeComponent,
    SellTradeComponent,
    TradeDetailsComponent,
    DiscussionComponent,
    BookOrderComponent,
    P2pTradeProcessingComponent,

    TradesComponent,
    CreateTradesComponent,
    TradeShowComponent,
    P2pTradeOrdersComponent,
    NotificationsComponent,
    TraderProfileComponent,
    TradeReviewComponent,




  ],
  imports: [
    //NgFlashMessagesModule.forRoot(),
    BrowserModule,
    AppRoutingModule,
    // NgxUiLoaderModule,
    BrowserAnimationsModule,
    //BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,

    NgxDropzoneModule,


    //ToastrModule,
    //CKEditorModule, NgbModule,
    //NgxSpinnerModule,

    MatSliderModule, MatTableModule,

    //NgxDropzoneModule,
 


    MatSnackBarModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,

  ],
  exports: [

    FormsModule,
    ReactiveFormsModule
  ],
  providers: [
    AuthService,
    JobService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptorService, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
