<div class="card     ">
   <div class="card-body">

      <table class="table table-hover table-borderless  table-sm">

         <tbody>
            <tr class="tr" *ngFor="let q of ticker">

               <td routerLinkActive="router-link-active">
                  <a [routerLink]="['/system',q.c1 ,q.c2]"> <small>{{q.c1 }} / {{q.c2 }}</small> </a>
               </td>

               <td><small>{{q.rate | number}}</small>
            </tr>
         </tbody>
      </table>

      <table mat-table [dataSource]="ticker" class="mat-elevation-z8">

         <!--- Note that these columns can be defined in any order.
               The actual rendered columns are set as a property on the row definition" -->

         <!-- Position Column -->
         <ng-container matColumnDef="c1">
            <th mat-header-cell *matHeaderCellDef> Symbol </th>
            <td mat-cell *matCellDef="let q"> {{q.c1 }} / {{q.c2 }} </td>
         </ng-container>

         <!-- Name Column -->
         <ng-container matColumnDef="rate">
            <th mat-header-cell *matHeaderCellDef> Rate </th>
            <td mat-cell *matCellDef="let  q  "> {{q.rate | number}} </td>
         </ng-container>

         <tr mat-header-row></tr>
         <tr mat-row *matRowDef="let row; "></tr>
      </table>
   </div>

</div>