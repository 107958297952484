import { Component, HostBinding, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { SampleDialogComponent } from './sample-dialog/sample-dialog.component';
import { OverlayContainer } from '@angular/cdk/overlay';
import { AuthService } from './authentication/auth.service';
import { JobService } from './component/job.service';
import { interval } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Gemsgreen';

  @HostBinding('class') className = '';


  unread_notification_count: any;

  toggleControl = new FormControl(false);
  opened: boolean = true;
  shouldRun: boolean = true;
  constructor(private dialog: MatDialog, private overlay: OverlayContainer, public authService: AuthService, private jobService: JobService) { }

  ngOnInit(): void {
    this.toggleControl.valueChanges.subscribe((darkMode) => {
      const darkClassName = 'darkMode';
      this.className = darkMode ? darkClassName : '';
      if (darkMode) {
        this.overlay.getContainerElement().classList.add(darkClassName);
      } else {
        this.overlay.getContainerElement().classList.remove(darkClassName);
      }
    });


    interval(30000)
      .subscribe(x => { this.getnotification_count(); });





  }

  openbox(): void {

    this.opened = !this.opened;


  }
  getnotification_count() {
    this.jobService.unread_notification_count()
      .subscribe(res => {
        this.unread_notification_count = res.count;

      });

  }

}
