

 
<span (click)="edit_trade(trade.id)">  Edit   </span>
                    
<span (click)="update_status(trade.id,'publish')"> 
    Publish   </span>


<span (click)="update_status(trade.id,'unpublish')"> Unpublish   </span>


<span (click)="update_status(trade.id,'delete')">  Delete   </span> 




  