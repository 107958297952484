import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from '@angular/router';
import { interval } from 'rxjs';
import { JobService } from '../../job.service';

@Component({
  selector: 'app-discussion',
  templateUrl: './discussion.component.html',
  styleUrls: ['./discussion.component.scss']
})



export class DiscussionComponent implements OnInit, OnChanges {
  @Input() id; // decorate the property with @Input()

  trade_details: any;
  trade_info: any;
  trade_order_info: any;
  chat_message: any;
  chat_form: FormGroup;
  constructor(private formBuilder: FormBuilder,
    private router: Router, private _snackBar: MatSnackBar,
    private activatedRoute: ActivatedRoute, private jobService: JobService) { }

  ngOnInit() {



  }


  ngOnChanges(changes: SimpleChanges) {

    interval(10000)
      .subscribe(x => { this.get_messages(this.id); });


    this.get_messages(this.id);

    this.chat_form = this.formBuilder.group({


      message: ['', Validators.required],

      chat_id: [this.id, Validators.required],


    });



  }



  send_message() {
    this.jobService.send_message(this.chat_form.value)
      .subscribe(res => {


        this._snackBar.open(res.message, "Close", {
          duration: 2000,
          verticalPosition: 'top',
          horizontalPosition: 'center'
        });


        this.get_messages(this.id);



      });
  }


  get_messages(id: any) {

    this.jobService.get_messages(id).subscribe(res => {

      this.chat_message = res.data;

    });
  }
}