<h5 class="card-title">Add Trade Form</h5>


<div class="tradeform">



  <form [formGroup]="add_new_trade_Form" (ngSubmit)="onSubmit()">

    <div class="form-group">
      <label for="title"> Title</label>
      <input type="text" formControlName="title" placeholder="title" name="title" class="form-control" id="title">
    </div>


    <div class="form-group">
      <label for="type"> Type</label>

      <select class="form-control" formControlName="type">
        <option value="sell">Sell</option>
        <option value="buy">Buy</option>

      </select>
    </div>
    <div class="form-group">
      <label for="c1"> Currency One </label>
      <input type="text" formControlName="c1" name="c1" class="form-control" id="c1">
    </div>


    <div class="form-group">
      <label for="c2"> Currency Two </label>
      <input type="text" formControlName="c2" name="c2" class="form-control" id="c2">
    </div>



    <div class="form-group">
      <label for="minimum"> Minimum Limit</label>
      <input type="text" formControlName="minimum" name="minimum" class="form-control" id="minimum">
    </div>

    <div class="form-group">
      <label for="maximum"> Maximum Limit</label>
      <input type="text" formControlName="maximum" name="maximum" class="form-control" id="maximum">
    </div>


    <div class="form-group">
      <label for="terms_and_conditions"> Terms and conditions</label>

      <textarea rows="8" type="text" formControlName="terms_and_conditions" name="terms_and_conditions"
        class="form-control" id="terms_and_conditions">


                      </textarea>

    </div>


    <div class="form-group">
      <label for="payment_window ">Payment window (Duration taken to complete the payment ) </label>
      <input type="text" formControlName="payment_window" name="payment_window " class="form-control"
        id="payment_window ">
    </div>

    <div class="form-group">
      <label for="payment_method">Payment method</label>
      <input type="text" formControlName="payment_method" name="payment_method " class="form-control"
        id="payment_method">
    </div>


    <div class="form-group">
      <label for="margin">Margin</label>
      <input formControlName="margin" name="margin" class="form-control" id="margin">
    </div>




    <button class="btn btn-primary btn-sm  ">Submit</button>



  </form>
</div>