<mat-toolbar *ngIf="authService.isAuthenticated()">





    Pix Exchange <mat-icon (click)="openbox()">menu</mat-icon>
    <div class="flex-stretch"></div>


    <button matBadge="{{unread_notification_count}}" mat-raised-button routerLink="/notifications">
        <mat-icon>notifications</mat-icon>
    </button>






</mat-toolbar>


<mat-sidenav-container *ngIf="authService.isAuthenticated()" class="example-container">
    <mat-sidenav #sidenav mode="side" [(opened)]="opened">


        <mat-nav-list *ngIf="authService.isUser()">


            <a color="darkMode" mat-list-item routerLink="/home">
                Home </a>



            <a mat-list-item routerLink="/system/BTC/USDT">Trading</a>

            <a mat-list-item routerLink="/user-deposit"> Deposit </a>


            <a mat-list-item routerLink="/withdrawlist"> Withdraw </a>

            <a *ngIf="authService.showTrade()" mat-list-item routerLink="/trades/sell"> Sell trade </a>

            <a *ngIf="authService.showTrade()" mat-list-item routerLink="/trades/buy"> Buy trade </a>
            <a *ngIf="authService.showTrade()" mat-list-item routerLink="/manage_trade_orders"> Manage trade Orders </a>

            <a *ngIf="authService.showTrade()" mat-list-item routerLink="/notifications"> Notifications </a>






            <mat-accordion>



                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Trade
                        </mat-panel-title>


                    </mat-expansion-panel-header>




                    <a mat-list-item routerLink="/manage/trades"> Trades </a>



                </mat-expansion-panel>




                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Report
                        </mat-panel-title>


                    </mat-expansion-panel-header>




                    <a mat-list-item routerLink="/transaction"> Transactions </a>

                    <a mat-list-item routerLink="/withdrawlist"> Withdraw </a>

                    <a mat-list-item routerLink="/login-history">Login History
                    </a>
                </mat-expansion-panel>

                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Settings
                        </mat-panel-title>

                        <mat-panel-description>

                        </mat-panel-description>
                    </mat-expansion-panel-header>

                    <a mat-list-item routerLink="withdraw-address">Withdraw
                        Address </a>
                    <a mat-list-item routerLink="user-profile">Profile </a>
                    <a mat-list-item routerLink="change-password"> Change Password
                    </a>
                    <a mat-list-item routerLink="profile-enable">Two Factor
                        Authentication</a>





                </mat-expansion-panel>
            </mat-accordion>





            <a *ngIf="authService.isUser()" mat-list-item (click)="sidenav.toggle()" href=""
                (click)="authService.logout()" mat-list-item>Logout</a>

        </mat-nav-list>

        <mat-nav-list *ngIf="authService.isAdmin()">

            <a mat-list-item routerLink="/dashboard">Admin Home </a>


            <a mat-list-item routerLink="/system/BTC/APHK">Trading</a>



            <a mat-list-item routerLink="/trades/sell"> Sell trade </a>

            <a mat-list-item routerLink="/trades/buy"> Buy trade </a>


            <a mat-list-item routerLink="/manage_trade_orders"> Manage trade Orders </a>
            <a mat-list-item routerLink="addressform">Address</a>
            <a mat-list-item routerLink="/notifications"> Notifications </a>


            <a mat-list-item routerLink="user-deposit">Deposit</a>


            <a mat-list-item routerLink="/withdrawlist"> Withdraw </a>

            <a mat-list-item routerLink="user/list-user"> System Users </a>

            <a mat-list-item routerLink="add-users">Add User</a>



            <a mat-list-item routerLink="/manage/trades"> Trades </a>




            <mat-accordion>





                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            All Report
                        </mat-panel-title>


                    </mat-expansion-panel-header>

                    <a mat-list-item routerLink="all-deposit-address"> All Deposit
                        Address </a>

                    <a mat-list-item routerLink="user-dashboard-list"> User Dashboard </a>

                    <a mat-list-item routerLink="admin-wallet"> Admin Wallet </a>

                    <a mat-list-item routerLink="transaction"> All Transactions </a>

                    <a mat-list-item routerLink="deposit"> All Deposit </a>
                    <a mat-list-item routerLink="withdrawlist"> All Withdraw </a>

                    <a mat-list-item routerLink="notification">All Notification </a>

                </mat-expansion-panel>
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Profile
                        </mat-panel-title>

                        <mat-panel-description>

                        </mat-panel-description>
                    </mat-expansion-panel-header>

                    <a mat-list-item routerLink="admin-profile">Profile </a>
                    <a mat-list-item routerLink="change-password"> Change Password
                    </a>

                    <a mat-list-item routerLink="login-history">Login History </a>
                </mat-expansion-panel>

                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Settings
                        </mat-panel-title>

                        <mat-panel-description>

                        </mat-panel-description>
                    </mat-expansion-panel-header>


                    <a mat-list-item routerLink="general-setting">General Setting
                    </a>



                    <a mat-list-item routerLink="currency">Currency </a>
                    <a mat-list-item routerLink="pair-managment">Pair Management
                    </a>


                </mat-expansion-panel>
            </mat-accordion>
            <a mat-list-item (click)="sidenav.toggle()" (click)="authService.logout()" mat-list-item>Logout</a>

        </mat-nav-list>
    </mat-sidenav>

    <mat-sidenav-content>

        <div class="content mat-app-background" fxLayout="row" fxLayout.xs="column" fxLayoutGap="16px">



            <router-outlet></router-outlet>

        </div>
    </mat-sidenav-content>
</mat-sidenav-container>

<div *ngIf="!authService.isAuthenticated()">




    <router-outlet></router-outlet>
</div>