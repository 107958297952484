import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from '@angular/router';
import { JobService } from '../../job.service';

@Component({
  selector: 'app-trader-profile',
  templateUrl: './trader-profile.component.html',
  styleUrls: ['./trader-profile.component.scss']
})
export class TraderProfileComponent {

  trader:any;
  user_id:any;
  
  constructor(private formBuilder: FormBuilder,
    private router: Router, private _snackBar: MatSnackBar,
    private activatedRoute: ActivatedRoute,
    private jobService: JobService) { }



  ngOnInit() {
    this.user_id = this.activatedRoute.snapshot.params.user_id;


    this.jobService.about_trader(this.user_id)
      .subscribe(res => {
        this.trader = res.data;

      });

  }

  check_trade(id:any)
  {
    this.router.navigate(['trade_detail', id]);

  }
  check_trade_order(id:any)
  {
    this.router.navigate(['p2p-trade-processing', id]);

  }
}