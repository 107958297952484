import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";

import { JobService } from '../../job.service';

import { Transaction } from 'src/app/models/transaction.model';

import { animate, state, style, transition, trigger } from '@angular/animations';


import { MatFormFieldModule } from '@angular/material/form-field';



import { MatSnackBar } from '@angular/material/snack-bar';



@Component({
  selector: 'app-withdrawlist',
  templateUrl: './withdrawlist.component.html',
  styleUrls: ['./withdrawlist.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class WithdrawlistComponent implements OnInit {

  dataSource;

  columnsToDisplay = [];
  expandedElement: null;

  transactions: Transaction[];
  closeResult: string;


  editForm: FormGroup;
  addForm: FormGroup;


  loading: any;
  currency: any;
  testCount: any;


  constructor(private formBuilder: FormBuilder, private router: Router, private jobService: JobService,
    private _snackBar: MatSnackBar) { }

  ngOnInit() {

    this.loading = true;
    this.testCount = 1;



    this.addForm = this.formBuilder.group({

      currency: ['', Validators.required],
      amount: ['', Validators.required],
      widthdraw_address: ['', Validators.required],
      otp: ['', Validators.required]

    });

    this.jobService.currencylist()
      .subscribe(data => {

        this.currency = data;
        console.log(data);

      });

  }

  getOtp(){
    this.jobService.getOTP( )
    .subscribe(data => {

      this._snackBar.open(data.Message, "Close", {
        duration: 2000,

        verticalPosition: 'top',
        horizontalPosition: 'center'
      });



    });

  }
  widthdraw_list(type) {
    this.jobService.transactions_widthdraws(type)
      .subscribe(data => {

        this.transactions = data;


      });
  }





  withdrawstatus(id, email, name) {
    //alert(id);
    window.localStorage.removeItem("withdraw_id");
    window.localStorage.setItem("withdraw_id", id);
    window.localStorage.removeItem("user_name");
    window.localStorage.setItem("user_name", name);
    window.localStorage.removeItem("user_email");
    window.localStorage.setItem("user_email", email);
    this.router.navigate(['withdraw']);
  }


  submit_withdraw() {

    this.jobService.submit_withdraw(this.addForm.value)
      .subscribe(data => {

        this._snackBar.open(data.Message, "Close", {
          duration: 2000,

          verticalPosition: 'top',
          horizontalPosition: 'center'
        });

      });
  }


}
