import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { JobService } from '../../job.service';
import { MatSnackBar } from '@angular/material/snack-bar';

import { Transaction } from 'src/app/models/transaction.model';

@Component({
  selector: 'app-bank-account',
  templateUrl: './bank-account.component.html',
  styleUrls: ['./bank-account.component.css']
})
export class BankAccountComponent implements OnInit {

  constructor(private formBuilder: FormBuilder, private router: Router, private jobService: JobService, private _snackBar: MatSnackBar) { }
  addForm: FormGroup;
  currency: Transaction[];
  msg: any;
  balance: any;
  name: any;
  email: any;
  transactions;
  id: any;
  user_balance: any;
  user_id;
  currency1;
  ngOnInit() {

    let id = window.localStorage.getItem("user_id1");
    let t3 = window.localStorage.getItem("user_name");
    let t4 = window.localStorage.getItem("user_email");
    let t5 = window.localStorage.getItem("user_balance");
    this.name = t3;
    this.email = t4;
    this.id = id;

    this.jobService.alltransactionbyid(+this.id)
      .subscribe(data => {

        this.transactions = data;
        console.log("data", data);

      });
    this.jobService.balancecurrencylistbyid(+this.id)
      .subscribe(data => {

        this.currency1 = data;

      });
    this.jobService.balance()
      .subscribe(data => {
        console.log(data);




      });

    this.jobService.activecurrencylist()
      .subscribe(data => {

        this.currency = data;
        console.log(data);

      });
    let t = window.localStorage.getItem("user_id1");
    this.user_id = t;
    this.addForm = this.formBuilder.group({

      id: [],
      amount: ['', Validators.required],
      coin: ['', Validators.required],
      type: ['', Validators.required],
      description: ['', Validators.required]
    });
  }

  onChange(deviceValue) {
    console.log(deviceValue);
    // alert(deviceValue);
    this.currency1 = deviceValue;
    this.jobService.currencybalance(this.user_id, this.currency1)
      .subscribe(data => {
        console.log(data);
        this.balance = data['balance'];
        this.user_balance = data['userbalance'];
      });

  }
  onSubmit() {

    this.jobService.payment_otp()
      .subscribe(data => {

      });

    window.localStorage.removeItem("amount");
    window.localStorage.setItem("amount", this.addForm.value.amount);
    window.localStorage.removeItem("coin");
    window.localStorage.setItem("coin", this.addForm.value.coin);
    window.localStorage.removeItem("type");
    window.localStorage.setItem("type", this.addForm.value.type);
    window.localStorage.removeItem("description");
    window.localStorage.setItem("description", this.addForm.value.description);
    window.localStorage.removeItem("userbalance");
    window.localStorage.setItem("userbalance", this.user_balance);
    this.router.navigate(['confirm-payment']);

  }

}
