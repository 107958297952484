import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { JobService } from '../../job.service';
import { MatSnackBar } from '@angular/material/snack-bar';

import { Transaction } from 'src/app/models/transaction.model';

@Component({
  selector: 'app-currency',
  templateUrl: './currency.component.html',
  styleUrls: ['./currency.component.css']
})
export class CurrencyComponent implements OnInit {
  addForm: FormGroup;
  currency: Transaction[];
  msg: any;
  files: File[] = [];

  constructor(private formBuilder: FormBuilder, private router: Router, private jobService: JobService, private _snackBar: MatSnackBar) { }

  ngOnInit(): void {

    this.jobService.currencylist()
      .subscribe(data => {

        this.currency = data;
        console.log(data);

      });

    this.addForm = this.formBuilder.group({
      id: [],
      currency: ['', Validators.required],
      symbol: ['', Validators.required],
      deposit_charge: ['', Validators.required],
      withdraw_charge: ['', Validators.required],
      admin_wallet_address: ['', Validators.required],
      icon: [''],

    });
  }


  onSubmit() {
    this.jobService.addcurrency(this.addForm.value)
      .subscribe(data => {
        this.msg = data.Message;




        this._snackBar.open(this.msg, "Close", {
          duration: 2000,

          verticalPosition: 'top',
          horizontalPosition: 'center'

        });
        this.jobService.currencylist()
          .subscribe(data => {

            this.currency = data;
            console.log(data);

          });
      });
  }

  changecurrency(id, status) {
    this.jobService.changecurrency(+id, status)
      .subscribe(data => {
        console.log(data);


        this.jobService.currencylist()
          .subscribe(data => {

            this.currency = data;
            console.log(data);
          });
      });
  }
  delete_currency(id) {
    this.jobService.delete_currency(+id)
      .subscribe(data => {
        console.log(data);
        this._snackBar.open('Deleted Successfully', "Close", {
          duration: 2000,

          verticalPosition: 'top',
          horizontalPosition: 'center'

        });
        this.jobService.currencylist()
          .subscribe(data => {

            this.currency = data;
            console.log(data);

          });
      });
  }

  edit_currency(id) {
    this.router.navigate(['/edit-currency', id]);
  }


  onsendfile(file: any, type1: any) {
    //alert(file);
    this.addForm.controls['icon'].setValue(file);

  }

  onSelect(event, type1) {
    console.log(event, type1);
    //alert(type1);

    this.files.push(...event.addedFiles);


    this.readFile(this.files[0]).then(fileContents => {
      // Put this string in a request body to upload it to an API.
      console.log(fileContents);

      this.onsendfile(fileContents, type1);
    });

  }

  onRemove(event) {

    // alert("183");

    console.log(event);
    this.files.splice(this.files.indexOf(event), 1);
  }


  onFilesAdded(event) {
    //  alert("189");

    console.log(event);
    this.files.push(...event.addedFiles);

    this.readFile(this.files[0]).then(fileContents => {
      // Put this string in a request body to upload it to an API.
      console.log(fileContents);
    });
  }

  private async readFile(file: File): Promise<string | ArrayBuffer> {
    return new Promise<string | ArrayBuffer>((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = e => {
        return resolve((e.target as FileReader).result);
      };

      reader.onerror = e => {
        console.error("FileReader failed on file ${file.name}.");
        return reject(null);
      };

      if (!file) {
        console.error('No file to read.');
        return reject(null);
      }

      reader.readAsDataURL(file);
    });
  }
}
