<form [formGroup]="orderForm" *ngIf="trade">



    <label class="label">Price {{trade.c1}}</label>


    <input type="text" (keyup)="onKey($event)" class="form-control    " id="c1_value" formControlName="c1_value"
        name="c1_value">


    <input type="hidden" class="form-control" id="c1" formControlName="c1" name="c1" value="{{trade.c1}}">



    <label class="label"> Amount {{trade.c2}}</label>


    <input type="text" class="form-control     " id="c2_value" (keyup)="onKey1($event)" formControlName="c2_value"
        name="c2_value">


    <input type="hidden" class="form-control  mt-2" id="c2" formControlName="c2" name="c2" value="{{trade.c2}}">


    <label class="message mt-3"> Message</label>

    <textarea class="form-control     " formControlName="message">


</textarea>


    <button (click)="start_p2p_trade()" class="btn btn-success   btn-block  mt-2   w-100  ">Submit Order</button>


</form>